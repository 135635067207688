import React from "react";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import { t, Trans } from "@lingui/macro";
import _ from "lodash";
import { Grid, Header } from "semantic-ui-react";

import i18n from "modules/i18n/i18nConfig";
import { useGetPumpBackgroundQuery, useGetPumpMonitoringQuery } from "modules/machine/machineService";
import { setTime } from "modules/overview/overviewSlice";
import { dateRangeOptions } from "modules/time/utils";

import TimePanel from "modules/common/components/TimePanel";
import MessageDisplay from "modules/common/components/MessageDisplay";
import GraphicPumpHeadFlow from "../graphic/GraphicPumpHeadFlow";
import GraphicPumpHistogram from "../graphic/GraphicPumpHistogram";
import HeaderWHelper from "modules/common/components/HeaderWHelper";

const rangeOptions = _.filter(dateRangeOptions(), (item) => !_.includes(["24h", "48h", "72h"], item.value));

const TabPumpMonitoring = (props) => {
    const dispatch = useDispatch();
    const { default_time, equipment, pump_description_empty } = props;
    const org = useSelector((state) => state.org);
    const pumptime = useSelector((state) => state.overview.pumptime);

    const id_machine = equipment?.machine?.id ?? null;
    const rangeTime = pumptime.time
        ? {
              start: moment(pumptime.time.start),
              end: moment(pumptime.time.end),
              plage: pumptime.time.plage
          }
        : default_time;

    const start = pumptime?.time?.start ?? null;
    const end = pumptime?.time?.end ?? null;
    const pump_params = {
        org: org.current,
        id_machine,
        start: start ? start : default_time.start.toISOString(),
        end: end ? end : default_time.end.toISOString()
    };
    const pump_monitoring = useGetPumpMonitoringQuery(pump_params, { skip: !org.current });
    const backgroundImg = useGetPumpBackgroundQuery({ id_machine, org: org.current }, { skip: !org.current || !id_machine });

    const { head_flow = null, graph_maxx = null, graph_maxy = null, histogram = null, bep_dist_bins = [] } = pump_monitoring?.data || {};

    return (
        <Grid id="pwaMain" verticalAlign="top" centered>
            <Grid.Row>
                <Grid.Column width={16} textAlign="center">
                    <TimePanel
                        time={rangeTime}
                        rangeOptions={rangeOptions}
                        accordion={true}
                        action={({ start, end, plage }) => {
                            dispatch(setTime({ start, end, plage, tab: "pumptime" }));
                        }}
                    />
                </Grid.Column>
            </Grid.Row>
            <Grid.Row>
                <Grid.Column mobile={16} computer={14} textAlign="center" className="pwaGraph">
                    <Header as="h3" block textAlign="center" attached="top">
                        <Trans>detail</Trans>
                    </Header>
                    {(pump_monitoring.isLoading || pump_monitoring.isFetching) && (
                        <MessageDisplay message={i18n._(t`loading data`)} level="info" iconName="circle notched" isLoading={true} />
                    )}
                    {pump_monitoring.isError && !pump_monitoring.isFetching && (
                        <>
                            {!pump_description_empty && (
                                <MessageDisplay message={i18n._(t`error loading data`)} level="error" iconName="warning circle" isLoading={false} />
                            )}
                            {pump_description_empty && (
                                <MessageDisplay message={i18n._(t`pump not configured`)} level="error" iconName="warning circle" isLoading={false} />
                            )}
                        </>
                    )}
                    {pump_monitoring.isSuccess && !pump_monitoring.isFetching && (
                        <GraphicPumpHeadFlow
                            serie={head_flow}
                            csvName={equipment?.name ?? "export"}
                            backgroundImage={backgroundImg.data}
                            maxX={graph_maxx}
                            maxY={graph_maxy}
                        />
                    )}
                </Grid.Column>
            </Grid.Row>
            <Grid.Row>
                <Grid.Column mobile={16} computer={14} textAlign="center" className="pwaGraph">
                    <Header attached="top" block textAlign="center">
                        <HeaderWHelper
                            text={<Trans>histogram pump</Trans>}
                            helper={
                                <Trans>
                                    <p>The histogram shows the distribution of the pump's operating zones.</p>
                                    <p>
                                        The central "optimum efficiency" zone corresponds to the area where the pump runs at its best efficiency
                                        (between 90% and 100% of its maximum efficiency).
                                    </p>
                                </Trans>
                            }
                        />
                    </Header>
                    {(pump_monitoring.isLoading || pump_monitoring.isFetching) && (
                        <MessageDisplay message={i18n._(t`loading data`)} level="info" iconName="circle notched" isLoading={true} />
                    )}
                    {pump_monitoring.isError && !pump_monitoring.isFetching && (
                        <>
                            {!pump_description_empty && (
                                <MessageDisplay message={i18n._(t`error loading data`)} level="error" iconName="warning circle" isLoading={false} />
                            )}
                            {pump_description_empty && (
                                <MessageDisplay message={i18n._(t`pump not configured`)} level="error" iconName="warning circle" isLoading={false} />
                            )}
                        </>
                    )}
                    {pump_monitoring.isSuccess && !pump_monitoring.isFetching && (
                        <GraphicPumpHistogram histogram={histogram} bep_dist_bins={bep_dist_bins} />
                    )}
                </Grid.Column>
            </Grid.Row>
        </Grid>
    );
};

export default TabPumpMonitoring;
