import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { t } from "@lingui/macro";
import _ from "lodash";
import moment from "moment";
import tinycolor from "tinycolor2";
import html2canvas from "html2canvas";
import { toast } from "react-toastify";
import { Button, Card, Grid, Popup, Segment } from "semantic-ui-react";
import {
    Crosshair,
    CustomSVGSeries,
    DiscreteColorLegend,
    FlexibleXYPlot,
    Highlight,
    Hint,
    HorizontalGridLines,
    LineMarkSeries,
    LineSeries,
    XAxis,
    YAxis
} from "react-vis";

import i18n, { multiI18nFormat } from "modules/i18n/i18nConfig";
import { checkMobileAndTablet } from "modules/common/utils";
import { toast_options, toast_options_err } from "modules/notification/notificationMiddleware";

import { Media } from "App";
import GenerateCsv from "modules/common/components/GenerateCsv";
import MessageDisplay from "modules/common/components/MessageDisplay";

const GraphicDetail = (props) => {
    const { time, stackBy, SerieType, customComponent, markSize } = props;

    const current_lng = useSelector((state) => state.i18n.current);
    const graphicRef = useRef(null);
    const [brushing, setBrushing] = useState(false);
    const [lastDrawLocation, setLastDrawLocation] = useState(null);

    const [series, setSeries] = useState([]);
    const [needTranslate, setNeedTranslate] = useState(true);
    const [hintValue, setHintValue] = useState(null);
    const [crosshairValues, setCrosshairValues] = useState([]);

    useEffect(() => {
        const i18nSeries = _.chain(props.data)
            .orderBy("name", "asc")
            .map((serie) => {
                return {
                    ...serie,
                    name: i18n._(serie.name),
                    title: `${i18n._(serie.name)} ${serie.isEvent ? "" : `(${serie.unit})`}`
                };
            })
            .value();

        setSeries(i18nSeries);
        setNeedTranslate(false);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [current_lng, props.data]);

    const handleSaveImage = async () => {
        if (!graphicRef.current) return;
        try {
            const canvas = await html2canvas(graphicRef.current);
            // Convertir en image
            const imageData = canvas.toDataURL("image/png");

            // Création d'un lien de téléchargement
            const a = document.createElement("a");
            a.href = imageData;
            a.download = `${i18n._(t`overview`)}.png`;
            document.body.appendChild(a);
            a.click();
            document.body.removeChild(a);
        } catch (error) {
            toast.error(i18n._(t`Image generation has failed`), toast_options_err);
        }
    };

    const maxY = _.chain(series)
        .filter((item) => item.disabled === false)
        .reduce((res, serie) => {
            _.each(serie.data, (item) => {
                res.push(item);
            });
            return res;
        }, [])
        .maxBy("y")
        .get("y")
        .value();

    const minY = _.chain(series)
        .filter((item) => item.disabled === false)
        .reduce((res, serie) => {
            _.each(serie.data, (item) => {
                res.push(item);
            });
            return res;
        }, [])
        .minBy("y")
        .get("y")
        .value();

    const minX = _.chain(series)
        .filter((item) => item.disabled === false)
        .reduce((res, serie) => {
            _.each(serie.data, (item) => {
                res.push(item);
            });
            return res;
        }, [])
        .minBy("x")
        .get("x")
        .value();

    // Processing minimum xAxis base on Start time panel && First element in Series
    // This trick prevent First BarChart overlap YAxis
    const xDomain = [Math.min(+time.start.clone(), minX), time.end.clone()];

    const tooltipHint = () => {
        return (
            <Hint value={hintValue}>
                <Card className="pwaOverviewTooltip" style={{ width: moment(hintValue.x).locale(current_lng).format("LLL").length * 8 }}>
                    <Card.Content>
                        <Card.Header>{moment(hintValue.x).locale(current_lng).format("LLL")}</Card.Header>
                    </Card.Content>
                    <Card.Content>
                        <Card.Description style={{ color: tinycolor(hintValue.color).setAlpha(1).toString() }}>
                            {hintValue.y_real === null && `- ${props.event ? "" : _.get(hintValue, "unit")}`}
                            {hintValue.y_real !== null &&
                                `${i18n.number(hintValue.y_real, { maximumFractionDigits: 2 })} ${props.event ? "" : _.get(hintValue, "unit")}`}
                        </Card.Description>
                    </Card.Content>
                </Card>
            </Hint>
        );
    };

    const tooltipCrosshair = () => (
        <Crosshair values={crosshairValues}>
            <Card>
                <Card.Content>
                    <Card.Header textAlign="center">{moment(crosshairValues[0].t).locale(current_lng).format("LLL")}</Card.Header>
                </Card.Content>
                <Card.Content>
                    <Grid centered verticalAlign="middle">
                        {_.chain(crosshairValues)
                            .map((item, idx) => {
                                return (
                                    <Grid.Row key={idx} style={{ color: tinycolor(item.color).setAlpha(1).toString() }}>
                                        <Grid.Column
                                            width={10}
                                            textAlign="left"
                                            style={{ whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis" }}
                                        >
                                            {i18n._(item.name)}
                                        </Grid.Column>
                                        <Grid.Column width={6} textAlign="right">
                                            {item.y !== null &&
                                                `${i18n.number(item.y, { maximumFractionDigits: 2 })} ${props.event ? "" : item.unit}`}
                                            {item.y === null && `- ${props.event ? "" : item.unit}`}
                                        </Grid.Column>
                                    </Grid.Row>
                                );
                            })
                            .value()}
                    </Grid>
                </Card.Content>
            </Card>
        </Crosshair>
    );

    const clickHandler = (serie_clk) => {
        const size_disable = _.chain(series)
            .filter((item) => item.disabled === false)
            .size()
            .value();
        const update_series = _.map(series, (item) => {
            if (item.title === _.get(serie_clk, "title")) return { ...item, disabled: size_disable <= 1 ? false : !item.disabled };
            return item;
        });
        setSeries(update_series);
    };

    const nearestXHandler = (value, { index }) => {
        const crossval = _.chain(series)
            .filter((item) => item.disabled === false)
            .reduce((res, serie) => {
                const data = _.chain(serie.data).find({ x: value.x }).value();
                if (data) {
                    res.push({ title: _.get(serie, "title"), ...data });
                }
                return res;
            }, [])
            .value();
        setCrosshairValues(crossval);
    };

    if (needTranslate) {
        return <MessageDisplay message={i18n._(t`loading data`)} level="info" iconName="circle notched" isLoading={true} />;
    }

    const emptyData = _.chain(series)
        .map((serie) => {
            if (props.event) {
                return _.chain(serie)
                    .get("data")
                    .filter((record) => _.isFinite(record.y) && record.y > 0)
                    .isEmpty(serie.data)
                    .value();
            }
            return _.chain(serie)
                .get("data")
                .filter((record) => _.isFinite(record.y))
                .isEmpty(serie.data)
                .value();
        })
        .every()
        .value();

    if (emptyData) {
        if (props.event) {
            return <MessageDisplay message={i18n._(t`no events`)} level="warning" iconName="warning circle" isLoading={false} />;
        }
        return <MessageDisplay message={i18n._(t`no data`)} level="warning" iconName="warning circle" isLoading={false} />;
    }

    const RenderSaveImage = () => {
        return (
            <Popup
                trigger={
                    <Button
                        onClick={async (e) => {
                            await toast.info(i18n._(t`Preparing the image to save`), toast_options);
                            setTimeout(() => {
                                //Add delay between toast message && image generation
                                handleSaveImage();
                            }, 1000);
                        }}
                        icon="file image outline"
                    />
                }
            >
                <Popup.Content>{i18n._(t`Save as image`)}</Popup.Content>
            </Popup>
        );
    };

    return (
        <>
            <Segment attached>
                <Grid columns={2}>
                    <Grid.Row>
                        <Grid.Column textAlign="left">{_.get(props, "children[0]", null)}</Grid.Column>
                        <Grid.Column textAlign="right">
                            <Media greaterThanOrEqual="computer">
                                <Button
                                    onClick={(event, data) => {
                                        setLastDrawLocation(null);
                                    }}
                                    icon="zoom-out"
                                    className="no-print"
                                />
                                <GenerateCsv series={series} filename={props.csvName || "export"} />
                                <RenderSaveImage />
                            </Media>
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
            </Segment>
            <Segment attached>
                <div ref={graphicRef}>
                    <FlexibleXYPlot
                        dontCheckIfEmpty
                        xType="time"
                        xDomain={lastDrawLocation ? lastDrawLocation : xDomain}
                        yDomain={[Math.min(0, minY), Math.max(props.event ? 2 : 10, maxY)]}
                        height={460}
                        margin={{ left: 60, right: 10, top: 10, bottom: 100 }}
                        stackBy={stackBy}
                        onMouseLeave={() => {
                            setCrosshairValues([]);
                        }}
                    >
                        <DiscreteColorLegend
                            className="pwaLegend400"
                            orientation="horizontal"
                            height={75}
                            items={series}
                            onItemClick={clickHandler}
                        />
                        <HorizontalGridLines />
                        <Highlight
                            enableY={false}
                            onBrushStart={(area) => {
                                if (area) setBrushing(true);
                            }}
                            onBrushEnd={(area) => {
                                if (area) {
                                    setBrushing(false);
                                    setLastDrawLocation([moment(_.get(area, "left")), moment(_.get(area, "right"))]);
                                }
                            }}
                        />
                        {_.isFinite(props.threshold) && (
                            <LineSeries
                                color={"red"}
                                data={[
                                    { x: lastDrawLocation ? lastDrawLocation[0].unix() * 1000 : time.start.unix() * 1000, y: props.threshold },
                                    { x: lastDrawLocation ? lastDrawLocation[1].unix() * 1000 : time.end.unix() * 1000, y: props.threshold }
                                ]}
                            />
                        )}
                        <XAxis
                            title={i18n._(t`time`)}
                            tickLabelAngle={-20}
                            tickFormat={(value, index, scale, tickTotal) => {
                                return multiI18nFormat(value, current_lng);
                            }}
                        />
                        {_.chain(series)
                            .filter((item) => item.disabled === false)
                            .orderBy((serie) => {
                                return serie.data.length;
                            }, "asc")
                            .map((serie, idx) => {
                                return (
                                    <SerieType
                                        key={idx}
                                        customComponent={customComponent}
                                        style={
                                            SerieType === CustomSVGSeries
                                                ? {
                                                      stroke: serie.color,
                                                      fill: serie.color
                                                  }
                                                : null
                                        }
                                        color={serie.color}
                                        fill={serie.color}
                                        size={markSize}
                                        getNull={(d) => d.y !== null}
                                        curve={"curveMonotoneX"}
                                        data={SerieType !== CustomSVGSeries ? serie.data : _.filter(serie.data, (record) => _.isFinite(record.y))}
                                        markStyle={brushing ? { pointerEvents: "none" } : { pointerEvents: "auto" }}
                                        onNearestX={!checkMobileAndTablet() ? nearestXHandler : null}
                                        onValueMouseOver={
                                            checkMobileAndTablet()
                                                ? (hintValue) => {
                                                      setHintValue(hintValue);
                                                  }
                                                : null
                                        }
                                        onValueMouseOut={
                                            checkMobileAndTablet()
                                                ? () => {
                                                      setHintValue(null);
                                                  }
                                                : null
                                        }
                                    />
                                );
                            })
                            .value()}
                        {!checkMobileAndTablet() && _.size(crosshairValues) !== 0 && tooltipCrosshair()}
                        {checkMobileAndTablet() && !_.isNull(hintValue) && tooltipHint()}
                        <YAxis
                            title={
                                _.chain(series)
                                    .reduce((res, serie) => {
                                        //Check same unit in all series
                                        const unit = _.get(serie, "unit");
                                        if (unit) res.push(unit);
                                        return res;
                                    }, [])
                                    .uniq()
                                    .size()
                                    .value() > 1 || props.event
                                    ? ""
                                    : _.chain(series).head().get("unit").value()
                            }
                            tickFormat={(value, index, scale, tickTotal) => {
                                const format = scale.tickFormat(tickTotal)(value);
                                if (typeof value === "number") {
                                    //Only display min && max on event Yaxis
                                    if (props.event && index !== 0 && index !== tickTotal) {
                                        return "";
                                    }
                                    return i18n.number(value, { maximumFractionDigits: 1 });
                                }
                                return format;
                            }}
                        />
                    </FlexibleXYPlot>
                    {!_.chain(props).get("children[1]").isNull().value() && (
                        <Segment basic style={{ marginTop: "15px" }}>
                            {_.chain(props).get("children[1]").value()}
                        </Segment>
                    )}
                </div>
            </Segment>
        </>
    );
};

GraphicDetail.defaultProps = {
    customComponent: null, //Use this param when SerieType is CustomSVGSeries (ex: voltage imbalance). Check react-vis library
    stackBy: null, //Use this param when SerieType is VerticalBarSerie (ex: sag/swell/trip)
    SerieType: LineMarkSeries, //Default lineMarkSeries
    markSize: 3, //Use this for changing mark on LineMarkSerie or CustomSVGSeries
    event: false //Use this for sag/swell/trip (event counter series )
};

export default GraphicDetail;
