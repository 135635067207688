import React, { useState } from "react";
import _ from "lodash";
import moment from "moment";
import { Icon, Menu, Popup, Tab } from "semantic-ui-react";
import { Trans, t } from "@lingui/macro";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTemperatureFull, faDroplet, faCloud, faViruses } from "@fortawesome/free-solid-svg-icons";

import i18n from "modules/i18n/i18nConfig";
import { roundedDate } from "modules/time/utils";
import { mt_type_elec_distri, mt_type_elec_efficiency, mt_type_elec_operationnal, mt_type_elec_state } from "../utils";

import Description from "modules/overview/description/components/Description";
import TabGeneric from "./tabs/TabGeneric";
import TabPumpEfficiency from "./tabs/TabPumpEfficiency";
import TabPumpMonitoring from "./tabs/TabPumpMonitoring";
import TabPredictHealthScore from "./tabs/TabPredictHealthScore";
import TabPredictOperatingPoints from "./tabs/TabPredictOperatingPoints";
import TabPredictSupplyAggregate from "./tabs/TabPredictSupplyAggregate";
import TabElecDistri from "./tabs/TabElecDistri";
import TabElecOperationnal from "./tabs/TabElecOperationnal";
import TabElecState from "./tabs/TabElecState";
import TabEnergy from "./tabs/TabEnergy";
import { Media } from "App";

const iconStyle = {
    margin: "0em 0.35714286em 0em 0em"
};

const iconMapperTab = {
    elec: (
        <Icon.Group>
            <Icon name="building" />
            <Icon corner="top right" name="bolt" />
        </Icon.Group>
    ),
    co2: <FontAwesomeIcon icon={faCloud} className="icon" style={iconStyle} />,
    temperature: <FontAwesomeIcon icon={faTemperatureFull} className="icon" style={iconStyle} />,
    hygrometry: <FontAwesomeIcon icon={faDroplet} className="icon" style={iconStyle} />,
    pm25: <FontAwesomeIcon icon={faViruses} className="icon" style={iconStyle} />,
    gas: <Icon name="gripfire" />,
    default: <Icon name="area graph" />
};

const OverviewTab = (props) => {
    const now = moment();
    const { equipment, dataflows = [], measurements = [] } = props;

    const [tabIndex, setTabIndex] = useState(0);

    const powertime = {
        start: roundedDate(now.clone().subtract(7, "days").startOf("days"), 10),
        end: roundedDate(now.clone().startOf("minute"), 10),
        plage: "7d"
    };
    const predicttime = {
        start: roundedDate(now.clone().subtract(7, "days").startOf("days"), 10),
        end: roundedDate(now.clone().startOf("minute"), 10),
        plage: "7d"
    };

    let panes = [];

    _.each(dataflows, (df, idx, alldf) => {
        if (df?.dataflowspec_tech?.name === "elec") {
            const has_expert = _.find(alldf, (item) => {
                return item?.dataflowspec_tech?.name === "elec_expert";
            });
            if (has_expert) return;
        }
        if (df?.dataflowspec?.name === "elec_predict") return; //Don't display 'elec_predict' tab in predict case (frequency && current measurement)
        let measures = _.filter(measurements, { dataflow: df?.id });

        const elec_efficiency_measurements = _.reduce(
            measures,
            (res, measure) => {
                if (_.includes(mt_type_elec_efficiency, measure?.measurementtype?.name)) {
                    res.push(measure);
                }
                return res;
            },
            []
        );

        if (_.size(elec_efficiency_measurements) > 0) {
            panes.push({
                menuItem: (_, props) => {
                    const { active } = props;
                    return (
                        <Menu.Item key="energetic efficiency" {...props}>
                            <Media lessThan="computer">
                                <Popup
                                    content={i18n._(t`energetic efficiency`)}
                                    trigger={
                                        <Icon.Group>
                                            <Icon name="building" />
                                            <Icon corner="top right" name="bolt" />
                                        </Icon.Group>
                                    }
                                />
                            </Media>
                            <Media between={["computer", "bigScreen"]}>
                                {!active && (
                                    <Popup
                                        content={i18n._(t`energetic efficiency`)}
                                        trigger={
                                            <Icon.Group>
                                                <Icon name="building" />
                                                <Icon corner="top right" name="bolt" />
                                            </Icon.Group>
                                        }
                                    />
                                )}
                                {active && (
                                    <>
                                        <Icon.Group>
                                            <Icon name="building" />
                                            <Icon corner="top right" name="bolt" />
                                        </Icon.Group>
                                        <Trans>energetic efficiency</Trans>
                                    </>
                                )}
                            </Media>
                            <Media greaterThanOrEqual="bigScreen">
                                <Icon.Group>
                                    <Icon name="building" />
                                    <Icon corner="top right" name="bolt" />
                                </Icon.Group>
                                <Trans>energetic efficiency</Trans>
                            </Media>
                        </Menu.Item>
                    );
                },
                render: () => (
                    <Tab.Pane>
                        <TabEnergy equipment={equipment} measures={elec_efficiency_measurements} default_time={powertime} />
                    </Tab.Pane>
                )
            });
        }

        //Add "specific tab" per each dataflows
        const elec_distri_measurements = _.reduce(
            measures,
            (res, measure) => {
                if (_.includes(mt_type_elec_distri, measure?.measurementtype?.name)) {
                    res.push(measure);
                }
                return res;
            },
            []
        );

        /* ELEC DISTRI */
        if (_.size(elec_distri_measurements) > 0) {
            panes.push({
                menuItem: (_, props) => {
                    const { active } = props;
                    return (
                        <Menu.Item key="electrical distribution" {...props}>
                            <Media lessThan="computer">
                                <Popup
                                    content={i18n._(t`electrical distribution`)}
                                    trigger={
                                        <Icon.Group>
                                            <Icon name="hubspot" />
                                            <Icon corner="top right" name="bolt" />
                                        </Icon.Group>
                                    }
                                />
                            </Media>
                            <Media between={["computer", "bigScreen"]}>
                                {!active && (
                                    <Popup
                                        content={i18n._(t`electrical distribution`)}
                                        trigger={
                                            <Icon.Group>
                                                <Icon name="hubspot" />
                                                <Icon corner="top right" name="bolt" />
                                            </Icon.Group>
                                        }
                                    />
                                )}
                                {active && (
                                    <>
                                        <Icon.Group>
                                            <Icon name="hubspot" />
                                            <Icon corner="top right" name="bolt" />
                                        </Icon.Group>
                                        <Trans>electrical distribution</Trans>
                                    </>
                                )}
                            </Media>
                            <Media greaterThanOrEqual="bigScreen">
                                <Icon.Group>
                                    <Icon name="hubspot" />
                                    <Icon corner="top right" name="bolt" />
                                </Icon.Group>
                                <Trans>electrical distribution</Trans>
                            </Media>
                        </Menu.Item>
                    );
                },
                render: () => (
                    <Tab.Pane>
                        <TabElecDistri equipment={equipment} measures={elec_distri_measurements} default_time={powertime} />
                    </Tab.Pane>
                )
            });
        }

        const elec_operationnal_measurements = _.reduce(
            measures,
            (res, measure) => {
                if (_.includes(mt_type_elec_operationnal, measure?.measurementtype?.name)) {
                    res.push(measure);
                }
                return res;
            },
            []
        );

        /* ELEC OPERATIONNAL */
        if (_.size(elec_operationnal_measurements) > 0) {
            panes.push({
                menuItem: (_, props) => {
                    const { active } = props;
                    return (
                        <Menu.Item key="power operational" {...props}>
                            <Media lessThan="computer">
                                <Popup content={i18n._(t`operational`)} trigger={<Icon name="cogs" />} />
                            </Media>
                            <Media between={["computer", "bigScreen"]}>
                                {!active && <Popup content={i18n._(t`operational`)} trigger={<Icon name="cogs" />} />}
                                {active && (
                                    <>
                                        <Icon name="cogs" />
                                        <Trans>operational</Trans>
                                    </>
                                )}
                            </Media>
                            <Media greaterThanOrEqual="bigScreen">
                                <Icon name="cogs" />
                                <Trans>operational</Trans>
                            </Media>
                        </Menu.Item>
                    );
                },
                render: () => (
                    <Tab.Pane>
                        <TabElecOperationnal equipment={equipment} measures={elec_operationnal_measurements} default_time={powertime} />
                    </Tab.Pane>
                )
            });
        }

        const elec_state_measurements = _.reduce(
            measures,
            (res, measure) => {
                if (_.includes(mt_type_elec_state, measure?.measurementtype?.name)) {
                    res.push(measure);
                }
                return res;
            },
            []
        );

        /* ELEC STATE */
        if (_.size(elec_state_measurements) > 0) {
            panes.push({
                menuItem: (_, props) => {
                    const { active } = props;
                    return (
                        <Menu.Item key="electrical state" {...props}>
                            <Media lessThan="computer">
                                <Popup
                                    content={i18n._(t`electrical state`)}
                                    trigger={
                                        <Icon.Group>
                                            <Icon name="bolt" />
                                            <Icon corner="top right" name="heartbeat" />
                                        </Icon.Group>
                                    }
                                />
                            </Media>
                            <Media between={["computer", "bigScreen"]}>
                                {!active && (
                                    <Popup
                                        content={i18n._(t`electrical state`)}
                                        trigger={
                                            <Icon.Group>
                                                <Icon name="bolt" />
                                                <Icon corner="top right" name="heartbeat" />
                                            </Icon.Group>
                                        }
                                    />
                                )}
                                {active && (
                                    <>
                                        <Icon.Group>
                                            <Icon name="bolt" />
                                            <Icon corner="top right" name="heartbeat" />
                                        </Icon.Group>
                                        <Trans>electrical state</Trans>
                                    </>
                                )}
                            </Media>
                            <Media greaterThanOrEqual="bigScreen">
                                <Icon.Group>
                                    <Icon name="bolt" />
                                    <Icon corner="top right" name="heartbeat" />
                                </Icon.Group>
                                <Trans>electrical state</Trans>
                            </Media>
                        </Menu.Item>
                    );
                },
                render: () => (
                    <Tab.Pane>
                        <TabElecState equipment={equipment} measures={elec_state_measurements} default_time={powertime} />
                    </Tab.Pane>
                )
            });
        }

        // Remove all measures already process by previous tabs (maybe some exclusion, blacklist like dashboard)
        const remaining_measures = _.differenceWith(
            measures,
            _.uniq([...elec_efficiency_measurements, ...elec_distri_measurements, ...elec_operationnal_measurements, ...elec_state_measurements]),
            _.isEqual
        );

        if (_.size(remaining_measures) === 0) return;

        const popupTabIcon = iconMapperTab[df?.dataflowspec?.name ?? "default"] || iconMapperTab["default"];

        panes.push({
            menuItem: (_, props) => {
                const { active } = props;
                return (
                    <Menu.Item key={idx} {...props}>
                        <Media lessThan="computer">
                            <Popup content={i18n._(df?.dataflowspec?.name ?? "-")} trigger={popupTabIcon} />
                        </Media>
                        <Media between={["computer", "bigScreen"]}>
                            {!active && <Popup content={i18n._(df?.dataflowspec?.name ?? "-")} trigger={popupTabIcon} />}
                            {active && (
                                <>
                                    {popupTabIcon}
                                    {i18n._(df?.dataflowspec?.name ?? "-")}
                                </>
                            )}
                        </Media>
                        <Media greaterThanOrEqual="bigScreen">
                            {popupTabIcon}
                            {i18n._(df?.dataflowspec?.name ?? "-")}
                        </Media>
                    </Menu.Item>
                );
            },
            render: () => (
                <Tab.Pane>
                    <TabGeneric equipment={equipment} default_time={powertime} measurements={measures} />
                </Tab.Pane>
            )
        });
    });

    //Predict pump part
    const is_predict = equipment?.machine?.is_predict ?? false;
    if (is_predict) {
        panes.push({
            menuItem: (_, props) => {
                const { active } = props;
                return (
                    <Menu.Item key="score representation" {...props}>
                        <Media lessThan="computer">
                            <Popup
                                content={i18n._(t`score representation`)}
                                trigger={
                                    <Icon.Group>
                                        <Icon name="cog" />
                                        <Icon corner="top right" name="heartbeat" />
                                    </Icon.Group>
                                }
                            />
                        </Media>
                        <Media between={["computer", "bigScreen"]}>
                            {!active && (
                                <Popup
                                    content={i18n._(t`score representation`)}
                                    trigger={
                                        <Icon.Group>
                                            <Icon name="cog" />
                                            <Icon corner="top right" name="heartbeat" />
                                        </Icon.Group>
                                    }
                                />
                            )}
                            {active && (
                                <>
                                    <Icon.Group>
                                        <Icon name="cog" />
                                        <Icon corner="top right" name="heartbeat" />
                                    </Icon.Group>
                                    <Trans>score representation</Trans>
                                </>
                            )}
                        </Media>
                        <Media greaterThanOrEqual="bigScreen">
                            <Icon.Group>
                                <Icon name="cog" />
                                <Icon corner="top right" name="heartbeat" />
                            </Icon.Group>
                            <Trans>score representation</Trans>
                        </Media>
                    </Menu.Item>
                );
            },
            render: () => (
                <Tab.Pane>
                    <TabPredictHealthScore equipment={equipment} default_time={predicttime} />
                </Tab.Pane>
            )
        });
        panes.push({
            menuItem: (_, props) => {
                const { active } = props;
                return (
                    <Menu.Item key="predict operational" {...props}>
                        <Media lessThan="computer">
                            <Popup content={i18n._(t`your clusters`)} trigger={<Icon name="cogs" />} />
                        </Media>
                        <Media between={["computer", "bigScreen"]}>
                            {(mediaClassNames, renderChildren) =>
                                renderChildren && (
                                    <>
                                        {!active && <Popup content={i18n._(t`your clusters`)} trigger={<Icon name="cogs" />} />}
                                        {active && (
                                            <>
                                                <Icon name="cogs" />
                                                <Trans>your clusters</Trans>
                                            </>
                                        )}
                                    </>
                                )
                            }
                        </Media>
                        <Media greaterThanOrEqual="bigScreen">
                            {(mediaClassNames, renderChildren) =>
                                renderChildren && (
                                    <>
                                        <Icon name="cogs" />
                                        <Trans>your clusters</Trans>
                                    </>
                                )
                            }
                        </Media>
                    </Menu.Item>
                );
            },
            render: () => (
                <Tab.Pane>
                    <TabPredictOperatingPoints equipment={equipment} default_time={predicttime} />
                </Tab.Pane>
            )
        });
        panes.push({
            menuItem: (_, props) => {
                const { active } = props;
                return (
                    <Menu.Item key="predict supply state" {...props}>
                        <Media lessThan="computer">
                            <Popup
                                content={i18n._(t`predict supply state`)}
                                trigger={
                                    <Icon.Group>
                                        <Icon name="bolt" />
                                        <Icon corner="top right" name="sitemap" />
                                    </Icon.Group>
                                }
                            />
                        </Media>
                        <Media between={["computer", "bigScreen"]}>
                            {(mediaClassNames, renderChildren) =>
                                renderChildren && (
                                    <>
                                        {!active && (
                                            <Popup
                                                content={i18n._(t`predict supply state`)}
                                                trigger={
                                                    <Icon.Group>
                                                        <Icon name="bolt" />
                                                        <Icon corner="top right" name="sitemap" />
                                                    </Icon.Group>
                                                }
                                            />
                                        )}
                                        {active && (
                                            <>
                                                <Icon.Group>
                                                    <Icon name="bolt" />
                                                    <Icon corner="top right" name="sitemap" />
                                                </Icon.Group>
                                                <Trans>predict supply state</Trans>
                                            </>
                                        )}
                                    </>
                                )
                            }
                        </Media>
                        <Media greaterThanOrEqual="bigScreen">
                            {(mediaClassNames, renderChildren) =>
                                renderChildren && (
                                    <>
                                        <Icon.Group>
                                            <Icon name="bolt" />
                                            <Icon corner="top right" name="sitemap" />
                                        </Icon.Group>
                                        <Trans>predict supply state</Trans>
                                    </>
                                )
                            }
                        </Media>
                    </Menu.Item>
                );
            },
            render: () => (
                <Tab.Pane>
                    <TabPredictSupplyAggregate equipment={equipment} default_time={predicttime} />
                </Tab.Pane>
            )
        });
    }

    const is_pump = _.includes([7], equipment?.machine?.machine_type?.id); //check machine_type is centrifugal pump (id:7)
    const pump_component = _.find(equipment?.machine?.components, { comp_type: 6 });
    const is_pump_set = !_.isEmpty(pump_component?.description?.Hi ?? []);
    if (is_pump) {
        panes.push({
            menuItem: (_, props) => {
                const { active } = props;
                return (
                    <Menu.Item key="pump efficiency" {...props} disabled={pump_component === undefined}>
                        <Media lessThan="computer">
                            <Popup
                                content={i18n._(t`pump efficiency`)}
                                trigger={
                                    <Icon.Group>
                                        <Icon name="cog" />
                                        <Icon corner="top right" name="bolt" />
                                    </Icon.Group>
                                }
                            />
                        </Media>
                        <Media between={["computer", "bigScreen"]}>
                            {!active && (
                                <Popup
                                    content={i18n._(t`pump efficiency`)}
                                    trigger={
                                        <Icon.Group>
                                            <Icon name="cog" />
                                            <Icon corner="top right" name="bolt" />
                                        </Icon.Group>
                                    }
                                />
                            )}
                            {active && (
                                <>
                                    <Icon.Group>
                                        <Icon name="cog" />
                                        <Icon corner="top right" name="bolt" />
                                    </Icon.Group>
                                    <Trans>pump efficiency</Trans>
                                </>
                            )}
                        </Media>
                        <Media greaterThanOrEqual="bigScreen">
                            <Icon.Group>
                                <Icon name="cog" />
                                <Icon corner="top right" name="bolt" />
                            </Icon.Group>
                            <Trans>pump efficiency</Trans>
                        </Media>
                    </Menu.Item>
                );
            },
            render: () => (
                <Tab.Pane>
                    <TabPumpEfficiency equipment={equipment} default_time={powertime} pump_description_empty={!is_pump_set} />
                </Tab.Pane>
            )
        });
        panes.push({
            menuItem: (_, props) => {
                const { active } = props;
                return (
                    <Menu.Item key="pump operation" {...props} disabled={pump_component === undefined}>
                        <Media lessThan="computer">
                            <Popup
                                content={i18n._(t`pump operation`)}
                                trigger={
                                    <Icon.Group>
                                        <Icon name="cog" />
                                        <Icon corner="top right" name="bolt" />
                                    </Icon.Group>
                                }
                            />
                        </Media>
                        <Media between={["computer", "bigScreen"]}>
                            {!active && (
                                <Popup
                                    content={i18n._(t`pump operation`)}
                                    trigger={
                                        <Icon.Group>
                                            <Icon name="cog" />
                                            <Icon corner="top right" name="bolt" />
                                        </Icon.Group>
                                    }
                                />
                            )}
                            {active && (
                                <>
                                    <Icon.Group>
                                        <Icon name="cog" />
                                        <Icon corner="top right" name="bolt" />
                                    </Icon.Group>
                                    <Trans>pump operation</Trans>
                                </>
                            )}
                        </Media>
                        <Media greaterThanOrEqual="bigScreen">
                            <Icon.Group>
                                <Icon name="cog" />
                                <Icon corner="top right" name="bolt" />
                            </Icon.Group>
                            <Trans>pump operation</Trans>
                        </Media>
                    </Menu.Item>
                );
            },
            render: () => (
                <Tab.Pane>
                    <TabPumpMonitoring equipment={equipment} default_time={powertime} pump_description_empty={!is_pump_set} />
                </Tab.Pane>
            )
        });
    }

    panes.push({
        menuItem: (_, props) => {
            const { active } = props;
            return (
                <Menu.Item key="description" {...props}>
                    <Media lessThan="computer">
                        <Popup content={i18n._(t`Summary`)} trigger={<Icon name="info" />} />
                    </Media>
                    <Media between={["computer", "bigScreen"]}>
                        {!active && <Popup content={i18n._(t`Summary`)} trigger={<Icon name="info" />} />}
                        {active && (
                            <>
                                <Icon name="info" />
                                <Trans>Summary</Trans>
                            </>
                        )}
                    </Media>
                    <Media greaterThanOrEqual="bigScreen">
                        <Icon name="info" />
                        <Trans>Summary</Trans>
                    </Media>
                </Menu.Item>
            );
        },
        render: () => (
            <Tab.Pane>
                <Description equipment={equipment} />
            </Tab.Pane>
        )
    });

    if (is_predict) {
        //Reverse description tab for predict
        const descriptionTab = panes.pop();
        panes.unshift(descriptionTab);
    }

    return (
        <Tab
            panes={panes}
            activeIndex={tabIndex}
            onTabChange={(e, { activeIndex }) => setTabIndex(activeIndex)}
            menu={{ attached: true, tabular: true, className: "pwaOverviewTabMenu" }}
        />
    );
};

export default React.memo(OverviewTab);
