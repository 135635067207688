import React, { useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import _ from "lodash";
import moment from "moment";
import Datetime from "react-datetime";
import { t, Trans } from "@lingui/macro";
import { Button, Divider, Dropdown, Grid, Input, Label, Accordion, Segment, Checkbox } from "semantic-ui-react";

import i18n from "modules/i18n/i18nConfig";
import { Media } from "App";
import { processTimeRange } from "modules/time/utils";
import MessageDisplay from "modules/common/components/MessageDisplay";

const TimePanelContent = (props) => {
    const now = moment().startOf("minute");
    const { rangeOptions, minimumDeltaDay, maximumDeltaDay, rounded, timeFormat, start_date_limit } = props;
    const current_lng = useSelector((state) => state.i18n.current);
    const [start, setStart] = useState({ start: props.time.start, err: false });
    const [end, setEnd] = useState({ end: props.time.end, err: false });
    const [endBeforeStart, setEndBeforeStart] = useState(false);
    const [disable, setDisable] = useState(props.globalDisabled);
    const [plage, setPlage] = useState(props.time.plage);
    const [todoCompare, setTodoCompare] = useState(props.todoCompare);
    const [compareStart, setCompareStart] = useState({ start: props.compareTime.start, err: false });
    const [compareEnd, setCompareEnd] = useState({ end: props.compareTime.end, err: false });

    const delta_time = !start.err && !end.err ? end.end.clone().diff(start.start.clone(), "day") : null;
    const is_min_delta = _.isFinite(minimumDeltaDay) && delta_time < minimumDeltaDay;
    const is_max_delta = _.isFinite(maximumDeltaDay) && delta_time > maximumDeltaDay;

    const delta_time_compare = !compareStart.err && !compareEnd.err ? compareEnd.end.clone().diff(compareStart.start.clone(), "day") : null;
    const is_min_delta_compare = _.isFinite(minimumDeltaDay) && delta_time_compare < minimumDeltaDay;
    const is_max_delta_compare = _.isFinite(maximumDeltaDay) && delta_time_compare > minimumDeltaDay;

    const disable_btn =
        todoCompare === false
            ? disable || delta_time === null || is_min_delta || is_max_delta
            : disable || delta_time === null || delta_time_compare === null || is_min_delta_compare || is_max_delta_compare;

    useEffect(() => {
        setDisable(props.globalDisabled);
    }, [props.globalDisabled]);

    const onChangeStart = (date) => {
        if (props.usePlage === false) {
            setPlage("");
        }
        if (_.isString(date) || !date.isValid() || date > now || (start_date_limit !== null && date.isBefore(start_date_limit))) {
            setStart({ start: date, err: true });
            setDisable(true);
        } else {
            setStart({ start: date, err: false });
            if (end.err === false && end.end < date) {
                setEndBeforeStart(true);
                setDisable(true);
            } else {
                setEndBeforeStart(false);
                setDisable(false);
                if (todoCompare) {
                    const delta_time = !start.err && !end.err ? end.end.clone().diff(date.clone(), "day") : null;
                    if (_.isFinite(delta_time)) {
                        const new_comp_start = compareEnd.end.clone().subtract(delta_time, "day");
                        setCompareStart({ err: false, start: new_comp_start });
                    }
                }
            }
        }
    };
    const onChangeEnd = (date) => {
        if (props.usePlage === false) {
            setPlage("");
        }
        if (_.isString(date) || !date.isValid() || date > now || (start_date_limit !== null && date.isBefore(start_date_limit))) {
            setEnd({ end: date, err: true });
            setDisable(true);
        } else {
            setEnd({ end: date.clone(), err: false });
            if (props.usePlage) {
                // here specific for (detail) predict supply.TODO more generic
                switch (plage) {
                    case "1d":
                        setStart({ start: date.clone().subtract(1, "days"), err: false });
                        break;
                    case "2d":
                        setStart({ start: date.clone().subtract(2, "days"), err: false });
                        break;
                    case "7d":
                        setStart({ start: date.clone().subtract(7, "days").startOf("day"), err: false });
                        break;
                    default:
                        break;
                }
                setDisable(false);
            } else if (start.err === false && date < start.start) {
                setEndBeforeStart(true);
                setDisable(true);
            } else {
                setEndBeforeStart(false);
                setDisable(false);
                if (todoCompare) {
                    const delta_time = !start.err && !end.err ? date.clone().diff(start.start.clone(), "day") : null;
                    if (_.isFinite(delta_time)) {
                        const new_comp_start = compareEnd.end.clone().subtract(delta_time, "day");
                        setCompareStart({ err: false, start: new_comp_start });
                    }
                }
            }
        }
    };
    const onChangePlage = (e, data) => {
        const { start, end } = processTimeRange(data.value, rounded, timeFormat);
        setStart({ start: start, err: false });
        setEnd({ end: end, err: false });
        setPlage(data.value);
        setDisable(false);
        if (todoCompare) {
            setCompareStart({ start: start, err: false });
            setCompareEnd({ end: end, err: false });
        }
    };

    const onChangeCompareStart = (date) => {
        if (props.usePlage === false) {
            setPlage("");
        }
        if (_.isString(date) || !date.isValid() || date > now) {
            setCompareStart({ start: date, err: true });
            setDisable(true);
        } else {
            setCompareStart({ start: date, err: false });
            const delta_time = start.err === false && end.err === false ? end.end.diff(start.start, "day") : null;
            if (delta_time >= 0) {
                const new_end = date.clone().add(delta_time, "day");
                if (new_end > now) {
                    setDisable(true);
                } else {
                    setCompareEnd({ end: new_end, err: false });
                    setDisable(false);
                }
            } else {
                setDisable(false);
            }
        }
    };
    const onChangeCompareEnd = (date) => {
        if (props.usePlage === false) {
            setPlage("");
        }
        if (_.isString(date) || !date.isValid() || date > now) {
            setCompareEnd({ end: date, err: true });
            setDisable(true);
        } else {
            setCompareEnd({ end: date, err: false });
            setDisable(false);
        }
    };

    const runAnalyse = () => {
        if (props.action) {
            if (!todoCompare) {
                props.action({ start: start.start.toISOString(), end: end.end.toISOString(), plage, todoCompare });
            } else {
                props.action({
                    start: start.start.toISOString(),
                    end: end.end.toISOString(),
                    plage,
                    todoCompare,
                    compareStart: compareStart.start.toISOString(),
                    compareEnd: compareEnd.end.toISOString()
                });
            }
        }
        setDisable(true);
    };

    const renderInput = useCallback(
        ({ msg, ...rest }, openCalendar, closeCalendar) => (
            <div>
                {rest.error && (
                    <Label pointing={"below"} basic color="red">
                        {msg}
                    </Label>
                )}
                <Input labelPosition="left" {...rest} />
            </div>
        ),
        []
    );

    const isValidDate = (current) => {
        if (moment.isMoment(start_date_limit)) {
            // Rules used by activity log
            return current.isBetween(start_date_limit, now, "day", "[]");
        }

        return current.isBefore(now);
    };

    return (
        <Grid stackable>
            <Grid.Row centered>
                {is_max_delta && (
                    <Grid.Column width={16}>
                        <MessageDisplay
                            message={i18n._(t`maximum ${maximumDeltaDay} day(s)`)}
                            level="error"
                            iconName="warning circle"
                            isLoading={false}
                            attached={false}
                        />
                    </Grid.Column>
                )}
                {is_min_delta && (
                    <Grid.Column width={16}>
                        <MessageDisplay
                            message={i18n._(t`minimum ${minimumDeltaDay + 1} day(s)`)}
                            level="error"
                            iconName="warning circle"
                            isLoading={false}
                            attached={false}
                        />
                    </Grid.Column>
                )}
                {endBeforeStart && (
                    <Grid.Column width={16}>
                        <MessageDisplay
                            message={i18n._(t`end after start`)}
                            level="error"
                            iconName="warning circle"
                            isLoading={false}
                            attached={false}
                        />
                    </Grid.Column>
                )}
                <Grid.Column width={4}>
                    <Datetime
                        locale={current_lng}
                        timeFormat={timeFormat}
                        value={start.start}
                        strictParsing={true}
                        onChange={onChangeStart}
                        renderInput={renderInput}
                        inputProps={{
                            label: `${i18n._(t`from`)}:`,
                            error: start.err,
                            msg: i18n._(t`invalid day`),
                            fluid: true,
                            icon: "calendar",
                            disabled: props.disable_start
                        }}
                        isValidDate={isValidDate}
                    />
                </Grid.Column>
                <Grid.Column width={4}>
                    <Datetime
                        locale={current_lng}
                        timeFormat={timeFormat}
                        value={end.end}
                        strictParsing={true}
                        onChange={onChangeEnd}
                        renderInput={renderInput}
                        inputProps={{
                            label: `${i18n._(t`to`)}:`,
                            error: end.err,
                            msg: i18n._(t`invalid day`),
                            fluid: true,
                            icon: "calendar",
                            disabled: props.disable_end
                        }}
                        isValidDate={isValidDate}
                    />
                </Grid.Column>
                {!props.hide_plage && (
                    <>
                        <Grid.Column width={1}>
                            <Divider vertical>
                                <Trans>or</Trans>
                            </Divider>
                        </Grid.Column>
                        <Grid.Column width={4}>
                            <Dropdown
                                fluid
                                button
                                className="icon"
                                floating
                                labeled
                                icon="calendar"
                                selection
                                options={_.map(rangeOptions, ({ key, text, value }) => ({ key, text: i18n._(text), value }))}
                                placeholder={i18n._(t`date range`)}
                                onChange={onChangePlage}
                                value={plage}
                            />
                        </Grid.Column>
                    </>
                )}
                {!props.hide_btn && (
                    <Grid.Column width={3} textAlign="center">
                        <Button fluid disabled={disable_btn} secondary={!disable_btn} onClick={runAnalyse}>
                            <Trans>toanalyze</Trans>
                        </Button>
                    </Grid.Column>
                )}
            </Grid.Row>
            {props.enableCompare && (
                <Grid.Row>
                    <Grid.Column width={15} textAlign="center">
                        <Segment basic>
                            <Checkbox
                                toggle
                                disabled={delta_time === null}
                                label={<label>{`${i18n._(t`compare with period`)}`}</label>}
                                checked={todoCompare}
                                onChange={(e, data) => {
                                    if (data.checked) {
                                        setCompareStart(start);
                                        setCompareEnd(end);
                                    }
                                    setTodoCompare(data.checked);
                                    setDisable(false);
                                }}
                            />
                        </Segment>
                    </Grid.Column>
                </Grid.Row>
            )}
            {props.enableCompare && todoCompare && (
                <>
                    {is_min_delta_compare && (
                        <Grid.Column width={16}>
                            <MessageDisplay
                                message={i18n._(t`minimum ${minimumDeltaDay + 1} day(s)`)}
                                level="error"
                                iconName="warning circle"
                                isLoading={false}
                                attached={false}
                            />
                        </Grid.Column>
                    )}
                    {is_max_delta_compare && (
                        <Grid.Column width={16}>
                            <MessageDisplay
                                message={i18n._(t`maximum ${maximumDeltaDay} day(s)`)}
                                level="error"
                                iconName="warning circle"
                                isLoading={false}
                                attached={false}
                            />
                        </Grid.Column>
                    )}
                    {delta_time !== delta_time_compare && (
                        <Grid.Column width={16}>
                            <MessageDisplay
                                message={i18n._(t`time diff compare`)}
                                level="error"
                                iconName="warning circle"
                                isLoading={false}
                                attached={false}
                            />
                        </Grid.Column>
                    )}
                    <Grid.Column width={4}>
                        <Datetime
                            locale={current_lng}
                            timeFormat={timeFormat}
                            value={compareStart.start}
                            strictParsing={true}
                            onChange={onChangeCompareStart}
                            renderInput={renderInput}
                            inputProps={{
                                label: `${i18n._(t`from`)}:`,
                                error: compareStart.err,
                                msg: i18n._(t`invalid day`),
                                fluid: true,
                                icon: "calendar"
                            }}
                            isValidDate={(current) => {
                                return current.isBefore(now);
                            }}
                        />
                    </Grid.Column>
                    <Grid.Column width={4}>
                        <Datetime
                            locale={current_lng}
                            timeFormat={timeFormat}
                            value={compareEnd.end}
                            strictParsing={true}
                            onChange={onChangeCompareEnd}
                            renderInput={renderInput}
                            inputProps={{
                                label: `${i18n._(t`to`)}:`,
                                error: compareEnd.err,
                                msg: i18n._(t`invalid day`),
                                fluid: true,
                                icon: "calendar",
                                disabled: props.disable_compare_end
                            }}
                            isValidDate={(current) => {
                                return current.isBefore(now);
                            }}
                        />
                    </Grid.Column>
                </>
            )}
        </Grid>
    );
};

const ContentTime = React.memo(TimePanelContent);

const TimePanel = (props) => {
    return (
        <Grid verticalAlign="middle" divided="vertically" centered>
            {props.accordion && (
                <Media lessThan="tablet">
                    {(mediaClassNames, renderChildren) =>
                        renderChildren && (
                            <Accordion
                                fluid
                                panels={[
                                    {
                                        key: "selectors",
                                        title: i18n._(t`view parameters`),
                                        content: {
                                            content: <ContentTime {...props} />
                                        }
                                    }
                                ]}
                            />
                        )
                    }
                </Media>
            )}
            <Media greaterThanOrEqual={props.accordion ? "tablet" : "zero"}>
                {(mediaClassNames, renderChildren) =>
                    renderChildren && (
                        <Grid.Column mobile={16} tablet={16} computer={14} textAlign="center">
                            <ContentTime {...props} />
                        </Grid.Column>
                    )
                }
            </Media>
        </Grid>
    );
};

TimePanel.defaultProps = {
    rangeOptions: [],
    time: {
        plage: "7d",
        end: moment().startOf("minute"),
        start: moment().startOf("minute").subtract(7, "d")
    },
    timeFormat: "HH:mm",
    compareTime: {
        plage: "7d",
        end: moment().startOf("minute"),
        start: moment().startOf("minute").subtract(7, "d")
    },
    todoCompare: false, // control the display of compare time
    disable_compare_end: false, // Disable end compare time selector
    enableCompare: false, // Hide or show compare time
    globalDisabled: true, //Used by globalview for submit btn control (enable/disable)
    minimumDeltaDay: null, //Used to prevent analysis in specific rangeTime
    maximumDeltaDay: null, //Used to limit number of day for request (ex: PumpMonitoring)
    rounded: 10, //Used to rounded range time in plage selector
    disable_start: false,
    usePlage: false, //Used for changing start end with plage
    // isExtraDateRules: false, // Used to apply extra date rules (ex: ActivityLog)
    start_date_limit: null
};

export default React.memo(TimePanel);
