import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import _ from "lodash";
import { Accordion, Button, Checkbox, Dropdown, Grid, Input, Label, Modal, Segment, Table } from "semantic-ui-react";
import { t, Trans } from "@lingui/macro";

import i18n from "modules/i18n/i18nConfig";
import { removeAccents } from "modules/common/utils";

import GenericPaginate from "modules/common/components/GenericPaginate";
import { renderError } from "modules/common/components/form";
import { Media } from "App";
import MessageDisplay from "modules/common/components/MessageDisplay";

const renderLabelDropDown = (label) => {
    return {
        color: "green",
        content: `${label.text}`
    };
};

const DropdownAdapterMeasurement = (props) => {
    const {
        displayAddBtn,
        input,
        meta,
        label,
        isRequired,
        options,
        sites,
        usages,
        siteDisabled = false,
        sites_filter = [],
        restricted_mttypes_id = [],
        restricted_cats_id = [],
        ...rest
    } = props;

    const multiple = !!rest.multipleselect;

    //Current measure
    const [selectedMeasures, setSelectedMeasures] = useState(multiple ? [] : null);
    //Pagination
    const [open, setOpen] = useState(false);
    const [page, setPage] = useState(0);
    const [rowPerPage, setRowPerPage] = useState(5);
    //Filter
    const [searchName, setSearchName] = useState("");
    const [siteFilter, setSiteFilter] = useState([]);
    const [zoneFilter, setZoneFilter] = useState([]);
    const [categoryFilter, setCategoryFilter] = useState([]);
    const [usageFilter, setUsageFilter] = useState([]);
    const [tagFilter, setTagFilter] = useState([]);
    const [mttypeFilter, setMttypeFilter] = useState([]);

    const [selectedAll, setSelectedAll] = useState(false);

    const { zone, tag, category, measurementtype, dataflow, equipment } = useSelector((state) => state);

    useEffect(() => {
        if (open) {
            setSiteFilter(sites_filter);
            //reset filter && page
            setZoneFilter([]);
            setCategoryFilter([]);
            setUsageFilter([]);
            setTagFilter([]);
            setMttypeFilter([]);
            setPage(0);
            setRowPerPage(5);
            setSelectedMeasures(input.value);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [open]);

    useEffect(() => {
        setPage(0);
    }, [searchName, siteFilter, zoneFilter, categoryFilter, usageFilter, tagFilter, mttypeFilter]);

    const handleSelectedAll = (data, measures = []) => {
        const measures_ids = _.map(measures, (item) => item.id);
        setSelectedAll(data.checked);
        setSelectedMeasures(data.checked === false ? [] : measures_ids);
    };

    const handleSelectedMeasure = (event, data) => {
        const update_measures = data.checked === false ? _.pull([...selectedMeasures], data.measure) : [...selectedMeasures, data.measure];

        setSelectedMeasures(update_measures);
    };

    const zoneOptions = _.chain(zone.zones)
        .reduce((res, zone) => {
            if (_.size(siteFilter) === 0) {
                res.push(zone);
            } else if (_.includes(siteFilter, zone.site_id)) {
                res.push(zone);
            }
            return res;
        }, [])
        .map(({ key, text, value }) => ({
            key,
            text: i18n._(text),
            value,
            content: <Label color="teal">{i18n._(text)}</Label>
        }))
        .orderBy((item) => {
            return removeAccents(item.text).toLowerCase();
        }, "asc")
        .value();

    const filters = (
        <Grid verticalAlign="middle" centered>
            <Grid.Column width={16}>
                <Input
                    fluid
                    icon="search"
                    placeholder={i18n._(t`search identifier`)}
                    onChange={(e, { value }) => {
                        setSearchName(value);
                    }}
                    value={searchName}
                    onKeyDown={(e) => {
                        if (e.keyCode === 32) {
                            e.stopPropagation();
                        }
                    }}
                />
            </Grid.Column>
            <Grid.Column mobile={16} tablet={8} computer={8}>
                <Dropdown
                    fluid
                    options={_.map(sites, ({ key, text, value }) => ({ key, text, value, content: <Label color="blue">{text}</Label> }))}
                    placeholder={i18n._(t`select sites`)}
                    multiple
                    selection
                    onChange={(e, { value }) => {
                        setSiteFilter(value);
                        setZoneFilter([]);
                    }}
                    renderLabel={(label) => ({ color: "blue", content: label.text })}
                    value={siteFilter}
                    disabled={siteDisabled}
                />
            </Grid.Column>
            <Grid.Column mobile={16} tablet={8} computer={8}>
                <Dropdown
                    fluid
                    disabled={_.size(siteFilter) === 0}
                    options={zoneOptions}
                    placeholder={i18n._(t`select zones`)}
                    multiple
                    selection
                    onChange={(e, { value }) => {
                        setZoneFilter(value);
                    }}
                    renderLabel={(label) => ({ color: "teal", content: label.text })}
                    value={zoneFilter}
                />
            </Grid.Column>
            <Grid.Column mobile={16} tablet={4} computer={4}>
                <Dropdown
                    fluid
                    options={_.chain(category.categories)
                        .filter((category) => {
                            if (_.isEmpty(restricted_cats_id)) {
                                return true;
                            }
                            return _.includes(restricted_cats_id, category.id);
                        })
                        .reduce((res, category) => {
                            if (!_.includes([5, 9, 11, 12, 27], category.id)) {
                                const { key, text, value } = category;
                                res.push({
                                    key,
                                    text: i18n._(text),
                                    value,
                                    content: <Label color="purple">{i18n._(text)}</Label>
                                });
                            }
                            return res;
                        }, [])
                        .orderBy((item) => {
                            return removeAccents(item.text).toLowerCase();
                        }, "asc")
                        .value()}
                    placeholder={i18n._(t`select categories`)}
                    multiple
                    selection
                    onChange={(e, { value }) => {
                        setCategoryFilter(value);
                    }}
                    renderLabel={(label) => ({ color: "purple", content: label.text })}
                    value={categoryFilter}
                />
            </Grid.Column>
            <Grid.Column mobile={16} tablet={4} computer={4}>
                <Dropdown
                    fluid
                    options={_.chain(measurementtype.measurementtypes)
                        .filter((item) => !_.includes([22, 19, 18, 8, 26, 13, 11, 14, 60], item.id)) // Remove MT_TYPE
                        .filter((item) => {
                            if (_.isEmpty(restricted_mttypes_id)) {
                                return true;
                            }
                            return _.includes(restricted_mttypes_id, item.id);
                        })
                        .filter((item) => {
                            //Remove electical index act/react in measurement type selector. Prevent text duplication
                            return !_.includes(["e_act_counter", "e_react+_counter", "e_react-_counter"], item.name);
                        })
                        .map(({ key, text, value }) => ({ key, text: i18n._(text), value, content: <Label color="yellow">{i18n._(text)}</Label> }))
                        .orderBy((item) => {
                            return removeAccents(item.text).toLowerCase();
                        }, "asc")
                        .value()}
                    placeholder={i18n._(t`select measurementtypes`)}
                    multiple
                    selection
                    onChange={(e, { value }) => {
                        setMttypeFilter(value);
                    }}
                    renderLabel={(label) => ({ color: "yellow", content: label.text })}
                    value={mttypeFilter}
                />
            </Grid.Column>
            <Grid.Column mobile={16} tablet={4} computer={4}>
                <Dropdown
                    fluid
                    options={_.chain(usages)
                        .map(({ key, text, value }) => ({ key, text: i18n._(text), value, content: <Label color="violet">{i18n._(text)}</Label> }))
                        .orderBy((item) => {
                            return removeAccents(item.text).toLowerCase();
                        }, "asc")
                        .value()}
                    placeholder={i18n._(t`select usages`)}
                    multiple
                    selection
                    onChange={(e, { value }) => {
                        setUsageFilter(value);
                    }}
                    renderLabel={(label) => ({ color: "violet", content: label.text })}
                    value={usageFilter}
                />
            </Grid.Column>
            <Grid.Column mobile={16} tablet={4} computer={4}>
                <Dropdown
                    fluid
                    options={_.chain(tag.tags)
                        .map(({ key, text, value }) => ({ key, text: i18n._(text), value, content: <Label color="grey">{i18n._(text)}</Label> }))
                        .orderBy((item) => {
                            return removeAccents(item.text).toLowerCase();
                        }, "asc")
                        .value()}
                    placeholder={i18n._(t`select tags`)}
                    multiple
                    selection
                    onChange={(e, { value }) => {
                        setTagFilter(value);
                    }}
                    renderLabel={(label) => ({ color: "grey", content: label.text })}
                    value={tagFilter}
                />
            </Grid.Column>
        </Grid>
    );

    const submitMeasurements = () => {
        input.onChange(selectedMeasures);
        setOpen(false);
    };

    const measures =
        _.chain(options)
            .map((measure) => {
                const df = _.find(dataflow.dataflows, { id: measure.dataflow }) || null;
                const eqpt = _.find(equipment.equipments, { id: _.get(df, "equipment", null) });
                return {
                    ...measure,
                    dataflow: df,
                    equipment: eqpt
                };
            })
            .reduce((res, measure) => {
                if (searchName === "") {
                    res.push(measure);
                } else if (measure.dataflow && _.includes(measure.dataflow.name.toLowerCase(), searchName.toLowerCase())) {
                    res.push(measure);
                }
                return res;
            }, [])
            .reduce((res, measure) => {
                if (_.size(siteFilter) === 0) {
                    res.push(measure);
                } else if (_.includes(siteFilter, _.get(measure, "dataflow.site.id", null))) {
                    res.push(measure);
                }
                return res;
            }, [])
            .reduce((res, measure) => {
                if (_.size(zoneFilter) === 0) {
                    res.push(measure);
                } else if (_.includes(zoneFilter, _.get(measure, "dataflow.zone.id", null))) {
                    res.push(measure);
                }
                return res;
            }, [])
            .reduce((res, measure) => {
                /* Tags filter */
                if (_.size(tagFilter) === 0) {
                    res.push(measure);
                    return res;
                }
                const count_tags = _.chain(measure)
                    .get("dataflow.tag_set")
                    .map((tag) => {
                        if (_.includes(tagFilter, tag.id)) return 1;
                        return 0;
                    })
                    .sum()
                    .value();
                if (count_tags) {
                    res.push(measure);
                }
                return res;
            }, [])
            .reduce((res, measure) => {
                if (_.size(usageFilter) === 0) {
                    res.push(measure);
                } else if (_.includes(usageFilter, _.get(measure, "dataflow.usage.id", null))) {
                    res.push(measure);
                }
                return res;
            }, [])
            .reduce((res, measure) => {
                if (_.size(categoryFilter) === 0) {
                    res.push(measure);
                } else if (_.includes(categoryFilter, _.get(measure, "dataflow.dataflowspec.id", null))) {
                    res.push(measure);
                }
                return res;
            }, [])
            .reduce((res, measure) => {
                if (_.size(restricted_mttypes_id) > 0 && !_.includes(restricted_mttypes_id, _.get(measure, "measurementtype.id", null))) {
                    return res;
                }
                if (_.size(mttypeFilter) === 0) {
                    res.push(measure);
                    return res;
                } else if (_.includes(mttypeFilter, _.get(measure, "measurementtype.id", null))) {
                    res.push(measure);
                }
                return res;
            }, [])
            .value() || [];

    return (
        <div className={`field ${isRequired ? "required" : ""} ${meta.error && (meta.touched || meta.dirty) ? "error" : ""}`}>
            {label && <label>{label}</label>}
            {renderError(meta)}
            <Modal
                centered={false}
                open={open}
                trigger={
                    displayAddBtn ? (
                        <Grid>
                            <Grid.Column mobile={13} tablet={15} computer={15} textAlign="center">
                                <Dropdown
                                    fluid
                                    multiple={multiple}
                                    selection
                                    search
                                    options={_.map(options, ({ key, text, value }) => ({
                                        key,
                                        text,
                                        value,
                                        content: multiple ? <Label color="green">{text}</Label> : null
                                    }))}
                                    value={input.value}
                                    onChange={(event, data) => {
                                        input.onChange(data.value);
                                    }}
                                    icon={null}
                                    name={label}
                                    {...rest}
                                    renderLabel={renderLabelDropDown}
                                    onFocus={(event, data) => {
                                        setOpen(true);
                                    }}
                                />
                            </Grid.Column>
                            <Grid.Column width={1} textAlign="center">
                                <Button
                                    type="button"
                                    icon="add"
                                    basic
                                    onClick={(event) => {
                                        setOpen(true);
                                    }}
                                />
                            </Grid.Column>
                        </Grid>
                    ) : (
                        <Dropdown
                            fluid
                            multiple={multiple}
                            selection
                            search
                            options={_.map(options, ({ key, text, value }) => ({
                                key,
                                text,
                                value,
                                content: multiple ? <Label color="green">{text}</Label> : null
                            }))}
                            value={input.value}
                            onChange={(event, data) => {
                                input.onChange(data.value);
                            }}
                            icon={null}
                            name={label ? label : null}
                            {...rest}
                            renderLabel={multiple ? renderLabelDropDown : null}
                            onFocus={(event, data) => {
                                setOpen(true);
                            }}
                        />
                    )
                }
            >
                <Modal.Header>
                    <Trans>choose your measurement</Trans>
                </Modal.Header>
                <Modal.Content scrolling={false}>
                    <Media lessThan="computer">
                        {(mediaClassNames, renderChildren) =>
                            renderChildren && (
                                <Segment id="pwaControls" attached="top">
                                    <Accordion
                                        panels={[
                                            {
                                                key: "filters",
                                                title: i18n._(t`filters`),
                                                content: { content: filters }
                                            }
                                        ]}
                                    />
                                </Segment>
                            )
                        }
                    </Media>
                    <Media greaterThanOrEqual="computer">
                        {(mediaClassNames, renderChildren) =>
                            renderChildren && (
                                <Segment id="pwaControls" attached="top">
                                    {filters}
                                </Segment>
                            )
                        }
                    </Media>
                    {_.size(measures) === 0 && (
                        <MessageDisplay
                            message={i18n._(t`no data criteria`)}
                            level="warning"
                            iconName="warning circle"
                            isLoading={false}
                            attached={false}
                        />
                    )}
                    <div style={{ overflowX: "auto" }}>
                        <Table striped celled unstackable compact>
                            <Table.Header>
                                <Table.Row>
                                    <Table.HeaderCell colSpan="2" textAlign="right">
                                        <GenericPaginate
                                            rowsLength={_.size(measures)}
                                            rowPerPage={rowPerPage}
                                            page={page}
                                            onPageChange={(event, data) => {
                                                setPage(data.activePage - 1);
                                            }}
                                            onChangeItemPageSize={(event, data) => {
                                                const { value } = data;
                                                setPage(0);
                                                setRowPerPage(parseInt(value));
                                            }}
                                        />
                                    </Table.HeaderCell>
                                </Table.Row>
                                <Table.Row>
                                    {multiple && (
                                        <Table.HeaderCell collapsing>
                                            <Checkbox
                                                name="selectedAll"
                                                checked={!!selectedAll}
                                                indeterminate={_.size(selectedMeasures) > 0 && _.size(measures) !== _.size(selectedMeasures)}
                                                onChange={(e, data) => handleSelectedAll(data, measures)}
                                            />
                                        </Table.HeaderCell>
                                    )}
                                    <Table.HeaderCell>
                                        <Trans>measurements</Trans>
                                    </Table.HeaderCell>
                                </Table.Row>
                            </Table.Header>
                            <Table.Body>
                                {measures.slice(page * rowPerPage, page * rowPerPage + rowPerPage).map((item, idx) => {
                                    return (
                                        <Table.Row
                                            key={idx}
                                            positive={multiple ? !!_.includes(selectedMeasures, item.id) : selectedMeasures === item.id}
                                        >
                                            {multiple && (
                                                <Table.Cell collapsing>
                                                    <Checkbox
                                                        checked={!!_.includes(selectedMeasures, item.id)}
                                                        measure={item.id}
                                                        onChange={handleSelectedMeasure}
                                                    />
                                                </Table.Cell>
                                            )}
                                            <Table.Cell onClick={!multiple ? (e) => setSelectedMeasures(item.id) : null}>
                                                {item.representation}
                                            </Table.Cell>
                                        </Table.Row>
                                    );
                                })}
                            </Table.Body>
                        </Table>
                    </div>
                </Modal.Content>
                <Modal.Actions>
                    {((multiple && _.size(selectedMeasures) === 0) || (!multiple && selectedMeasures === null)) && (
                        <Segment basic textAlign="center">
                            <MessageDisplay
                                message={i18n._(t`select at least 1 measurement`)}
                                level="info"
                                iconName="info circle"
                                isLoading={false}
                                attached={false}
                            />
                        </Segment>
                    )}
                    <Button
                        negative
                        onClick={(e, data) => {
                            if (input.value.length === 0) {
                                if (multiple) {
                                    input.onChange([]); //used for trigger react-final-form errors
                                } else {
                                    input.onChange("");
                                }
                            }
                            setOpen(false);
                            if (multiple) {
                                setSelectedAll(false);
                                setSelectedMeasures([]);
                            } else {
                                setSelectedMeasures(null);
                            }
                        }}
                    >
                        <Trans>cancel</Trans>
                    </Button>
                    <Button
                        type="button"
                        positive
                        labelPosition="right"
                        icon="checkmark"
                        content={i18n._(t`validate`)}
                        onClick={(e) => {
                            e.preventDefault();
                            submitMeasurements();
                        }}
                        disabled={(!multiple && !selectedMeasures) || (multiple && _.size(selectedMeasures) < 1)}
                    />
                </Modal.Actions>
            </Modal>
        </div>
    );
};

DropdownAdapterMeasurement.defaultProps = {
    displayAddBtn: true
};

export default DropdownAdapterMeasurement;
