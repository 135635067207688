import React, { useMemo, useEffect } from "react";
import { useSelector } from "react-redux";
import _ from "lodash";
import { t, Trans } from "@lingui/macro";
import { Form, Field } from "react-final-form";
import { toast } from "react-toastify";
import { Grid, Icon, Button, Header, Popup, Segment } from "semantic-ui-react";

import i18n from "modules/i18n/i18nConfig";
import { removeAccents } from "modules/common/utils";
import { comparison_options } from "../../../analysisAdvanced/utils";
import { MtTypesExclusions, WidgetType, WidgetTypeOptions, historic_options_widget } from "modules/dashboardDragNDrop/utils";
import { toast_options, toast_options_err } from "modules/notification/notificationMiddleware";
import { useCreateWidgetMutation, useUpdateWidgetMutation } from "../../dashboardDndService";
import { useGetSitesQuery } from "modules/site/siteService";
import { useGetUsagesQuery } from "modules/usage/usageService";
import { useGetMeasurementsQuery } from "modules/measurement/measurementService";
import { useGetMeasurementtypesQuery } from "modules/measurement/measurementtypeService";
import { useGetDataflowsQuery } from "modules/dataflow/dataflowService";
import { useGetUnitsQuery } from "modules/unit/unitService";
import { useGetCategoriesQuery } from "modules/category/categoryService";
import { useGetTagsQuery } from "modules/tag/tagService";
import { useGetZonesQuery } from "modules/area/areaService";
import { useGetEquipmentsQuery } from "modules/equipment/equipmentService";

import { DropDownAdapter, InputAdapter } from "modules/common/components/form";
import DropdownAdapterMeasurement from "modules/export/components/DropdownAdapterMeasurement";
import MessageDisplay from "modules/common/components/MessageDisplay";

const SparklineConsumption = (props) => {
    const { current_dashboard, onClose, widgetChoice, widget, disabled } = props;

    const { org } = useSelector((state) => state);

    const [createWidget, create] = useCreateWidgetMutation();
    const [updateWidget, update] = useUpdateWidgetMutation();

    // Create Widget
    useEffect(() => {
        if (create.isSuccess) {
            toast.success(i18n._(t`Successful create widget`), toast_options);
            onClose();
        }
        if (create.isError) {
            toast.error(i18n._(t`Can't create widget`), toast_options_err);
            onClose();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [create.isSuccess, create.isError, create.isLoading]);

    // Update Widget
    useEffect(() => {
        if (update.isSuccess) {
            toast.success(i18n._(t`Successful update widget`), toast_options);
            onClose();
        }
        if (update.isError) {
            toast.error(i18n._(t`Can't update widget`), toast_options_err);
            onClose();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [update.isSuccess, update.isError]);

    const sites = useGetSitesQuery({ org: org.current }, { skip: !org.current });
    const usages = useGetUsagesQuery({ org: org.current }, { skip: !org.current });
    const categories = useGetCategoriesQuery({ org: org.current }, { skip: !org.current });
    const zones = useGetZonesQuery({ org: org.current }, { skip: !org.current });
    const tags = useGetTagsQuery({ org: org.current }, { skip: !org.current });
    const equipments = useGetEquipmentsQuery({ org: org.current }, { skip: !org.current });
    const measurementtypes = useGetMeasurementtypesQuery({ org: org.current }, { skip: !org.current });
    const units = useGetUnitsQuery({ org: org.current }, { skip: !org.current });

    const dataflows = useGetDataflowsQuery(
        {
            org: org.current,
            categories: categories.data,
            tags: tags.data,
            sites: sites.data,
            zones: zones.data,
            usages: usages.data
        },
        {
            skip:
                !org.current ||
                categories.data === undefined ||
                tags.data === undefined ||
                sites.data === undefined ||
                zones.data === undefined ||
                usages.data === undefined
        }
    );
    const measurements = useGetMeasurementsQuery(
        { org: org.current, dataflows: dataflows.data, measurementtypes: measurementtypes.data, units: units.data, equipments: equipments.data },
        {
            skip: !org.current || equipments.data === undefined || dataflows.data === undefined || measurementtypes.data === undefined,
            units: units.data
        }
    );

    const err_list = [
        sites.isError,
        zones.isError,
        usages.isError,
        tags.isError,
        categories.isError,
        equipments.isError,
        dataflows.isError,
        measurements.isError,
        measurementtypes.isError,
        units.isError
    ];

    const status_list = [
        sites.isSuccess,
        zones.isSuccess,
        usages.isSuccess,
        tags.isSuccess,
        categories.isSuccess,
        equipments.isSuccess,
        dataflows.isSuccess,
        measurements.isSuccess,
        measurementtypes.isSuccess,
        units.isSuccess
    ];

    const restricted_mttypes_id = useMemo(() => {
        if (measurementtypes.isSuccess) {
            return _.reduce(
                measurementtypes.data,
                (res, mt_type) => {
                    if (mt_type?.datapoint_type === 2 || _.includes(MtTypesExclusions, mt_type?.name)) {
                        //remove events
                        return res;
                    }
                    res.push(mt_type.id);
                    return res;
                },
                []
            );
        }
        return [];
    }, [measurementtypes]);

    const initialValues = useMemo(() => {
        const defaultValues = {
            name: null,
            dashboard: current_dashboard.id,
            widget_type: widgetChoice,
            sites: _.size(sites.data) === 1 && sites.data[0].id ? [sites.data?.[0].id] : [],
            measurements: widgetChoice === WidgetType.SPARKLINE_CONSUMPTIONS[0] ? [] : null,
            x: 0,
            y: 0,
            w: 5,
            h: 5,
            aggregation: 1
        };
        const retrieve_measurements = () => {
            if (widget) {
                //update widget case
                if (widgetChoice === WidgetType.SPARKLINE_CONSUMPTIONS[0]) {
                    return widget?.measurements ?? [];
                }
                return _.head(widget?.measurements ?? []);
            }
            //create widget case
            return defaultValues.measurements;
        };

        return {
            ...defaultValues,
            ...widget,
            measurements: retrieve_measurements()
        };
    }, [widget, current_dashboard, widgetChoice, sites.data]);

    const submitForm = async (formdata) => {
        const new_formdata = {
            ...formdata,
            measurements: widgetChoice === WidgetType.SPARKLINE_CONSUMPTIONS[0] ? formdata.measurements : [formdata.measurements]
        };
        if (widget) {
            await updateWidget({ formdata: new_formdata, org: org.current, dashboard_id: current_dashboard.id });
        } else {
            await createWidget({ formdata: new_formdata, org: org.current, dashboard_id: current_dashboard.id });
        }
    };

    const title = i18n._(_.chain(WidgetTypeOptions).find({ value: widgetChoice }).get("text", "-").value());

    const MyHeader = () => (
        <Header attached="top" block style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
            {title} 
            <Popup position="bottom center" trigger={<Icon name="question circle" />}>
                <Segment basic style={{ width: "50vh" }}>
                    {widgetChoice === WidgetType.SPARKLINE_CONSUMPTION[0] && <Trans>Trend chart of a measurement over the selected period</Trans>}
                    {widgetChoice === WidgetType.SPARKLINE_CONSUMPTIONS[0] && (
                        <Trans>Trend chart of several measurements over the selected period</Trans>
                    )}
                </Segment>
            </Popup>
        </Header>
    );

    const sites_id = useMemo(() => {
        return _.map(sites.data, (item) => item.id);
    }, [sites.data]);

    const validate = (values) => {
        const err = {};
        if (!disabled) {
            const retrieve_sites = _.chain(values)
                .get("sites", [])
                .reduce((res, site_id) => {
                    const site = _.find(sites.data, { id: site_id });
                    if (site) {
                        res.push(site);
                    }
                    return res;
                }, []);

            const hasMultipleTimezone = retrieve_sites.groupBy("timezone").size().value() > 1;
            if (hasMultipleTimezone) {
                err.sites = <Trans>Sites must have the same timezone</Trans>;
            }

            if (_.includes(["cost", "kcost"], values.numerator_unit)) {
                const missingCurrency = _.reduce(
                    ["undefined", ""],
                    (result, currency) => {
                        return result || retrieve_sites.groupBy("conversions.currency").get(currency, []).size().value() > 0;
                    },
                    false
                );

                if (missingCurrency) {
                    err.sites = <Trans>Some site has no currency definition</Trans>;
                }

                const hasMultipleCurrency = retrieve_sites.groupBy("conversions.currency").size().value() > 1;
                if (hasMultipleCurrency) {
                    err.sites = <Trans>Sites must have the same currency</Trans>;
                }
            }
        }
        return err;
    };

    return (
        <>
            {(() => {
                if (_.some(err_list)) {
                    return (
                        <>
                            <MyHeader />
                            <MessageDisplay
                                message={i18n._(t`error loading data`)}
                                level="error"
                                iconName="warning circle"
                                isLoading={false}
                                attached={true}
                            />
                            <Segment attached textAlign="right" basic>
                                <Button type="button" negative onClick={() => onClose()}>
                                    <Trans>cancel</Trans>
                                </Button>
                            </Segment>
                        </>
                    );
                } else if (_.every(status_list)) {
                    return (
                        <Form
                            onSubmit={submitForm}
                            initialValues={initialValues}
                            validate={validate}
                            render={({ handleSubmit, submitting, pristine, invalid, values, form }) => {
                                if (
                                    (widgetChoice === WidgetType.SPARKLINE_CONSUMPTIONS[0] && !_.isArray(values?.measurements)) ||
                                    (widgetChoice === WidgetType.SPARKLINE_CONSUMPTION[0] && _.isArray(values?.measurements))
                                ) {
                                    // return empty DOM during WidgetChoice transition
                                    return null;
                                }

                                const not_visible_sites = values.id && _.chain(values.sites).difference(sites_id).size().value() > 0;

                                return (
                                    <form onSubmit={handleSubmit} className="ui form" style={{ marginTop: "1em" }}>
                                        <MyHeader />
                                        {!disabled && _.size(values.sites) === 0 && (
                                            <MessageDisplay
                                                message={i18n._(t`select at least 1 site`)}
                                                level="info"
                                                iconName="info circle"
                                                isLoading={false}
                                            />
                                        )}
                                        <Segment attached>
                                            <Grid stackable centered verticalAlign="top">
                                                <Grid.Row>
                                                    <Grid.Column width={16}>
                                                        <Field
                                                            name="name"
                                                            disabled={disabled}
                                                            label={i18n._(t`widget name`)}
                                                            placeholder={i18n._(t`enter name of widget`)}
                                                            isRequired={true}
                                                            component={InputAdapter}
                                                            validate={(value) => {
                                                                if (!disabled) {
                                                                    if (!value) {
                                                                        return <Trans>Required field</Trans>;
                                                                    }
                                                                }
                                                                return undefined;
                                                            }}
                                                            onKeyDown={(e) => {
                                                                //Hack to prevent parent dropdown tabspace interaction
                                                                if (e.keyCode === 32) {
                                                                    e.stopPropagation();
                                                                }
                                                            }}
                                                        />
                                                    </Grid.Column>
                                                </Grid.Row>
                                                <Grid.Row>
                                                    <Grid.Column width={8}>
                                                        <Field
                                                            name="sites"
                                                            placeholder={i18n._(t`select sites`)}
                                                            disabled={disabled}
                                                            noResultsMessage={i18n._(t`no result found`)}
                                                            options={_.chain(sites.data)
                                                                .map(({ key, text, value }) => ({ key, text, value }))
                                                                .orderBy((item) => {
                                                                    return removeAccents(item.text).toLowerCase();
                                                                }, "asc")
                                                                .value()}
                                                            multipleselect={1}
                                                            label={i18n._(t`select sites`)}
                                                            isRequired={true}
                                                            component={DropDownAdapter}
                                                            renderLabel={(label, index, defaultProps) => {
                                                                const labelProps =
                                                                    _.size(values.sites) <= 1 ? { ...defaultProps, onRemove: null } : defaultProps;
                                                                return { ...labelProps, color: "blue", content: i18n._(label.text) };
                                                            }}
                                                            validate={(value) => {
                                                                if (!disabled) {
                                                                    if (_.size(value) < 1) return <Trans>Required field</Trans>;
                                                                }
                                                                return undefined;
                                                            }}
                                                            customAction={(data) => {
                                                                //Refresh list of measurements when sites list updated
                                                                if (widgetChoice === WidgetType.SPARKLINE_CONSUMPTION[0]) {
                                                                    //Single measurement
                                                                    const measurement = _.find(measurements.data, {
                                                                        id: _.get(form.getFieldState("measurements"), "value", null)
                                                                    });
                                                                    if (measurement && !_.includes(data, measurement.site.id)) {
                                                                        form.change("measurements", null);
                                                                    }
                                                                }
                                                                if (widgetChoice === WidgetType.SPARKLINE_CONSUMPTIONS[0]) {
                                                                    //Multi Measurement
                                                                    const keep_measurements = _.chain(
                                                                        _.get(form.getFieldState("measurements"), "value", [])
                                                                    )
                                                                        .reduce((res, measure_id) => {
                                                                            const measurement = _.find(measurements.data, {
                                                                                id: measure_id
                                                                            });
                                                                            if (measurement && _.includes(data, measurement.site.id)) {
                                                                                res.push(measure_id);
                                                                            }
                                                                            return res;
                                                                        }, [])
                                                                        .value();
                                                                    form.change("measurements", keep_measurements);
                                                                }
                                                            }}
                                                        />
                                                        {not_visible_sites && (
                                                            <div style={{ color: "#794b02", fontStyle: "italic" }}>
                                                                <Trans>Some sites are present but you do not have permission to view them</Trans>
                                                            </div>
                                                        )}
                                                    </Grid.Column>
                                                    <Grid.Column width={8}>
                                                        <Field
                                                            name="historic"
                                                            className="icon"
                                                            disabled={disabled}
                                                            icon="calendar"
                                                            isRequired={true}
                                                            button
                                                            labeled
                                                            search={false}
                                                            placeholder={i18n._(t`historic`)}
                                                            label={i18n._(t`historic`)}
                                                            options={historic_options_widget}
                                                            component={DropDownAdapter}
                                                            validate={(value) => {
                                                                if (!disabled) {
                                                                    if (!value && value !== 0) return <Trans>Required field</Trans>;
                                                                }
                                                                return undefined;
                                                            }}
                                                        />
                                                    </Grid.Column>
                                                </Grid.Row>
                                                <Grid.Row>
                                                    {widgetChoice === WidgetType.SPARKLINE_CONSUMPTION[0] && (
                                                        <Grid.Column width={8}>
                                                            <Field
                                                                label={i18n._(t`select comparison`)}
                                                                name="comparison"
                                                                className="icon"
                                                                icon="law"
                                                                button
                                                                labeled
                                                                search={false}
                                                                disabled={disabled}
                                                                isRequired={true}
                                                                placeholder={i18n._(t`select comparison`)}
                                                                options={_.takeRight(comparison_options, 3)}
                                                                component={DropDownAdapter}
                                                                validate={(value) => {
                                                                    if (!disabled) {
                                                                        if (!value) return <Trans>Required field</Trans>;
                                                                    }
                                                                    return undefined;
                                                                }}
                                                            />
                                                        </Grid.Column>
                                                    )}
                                                    <Grid.Column width={widgetChoice === WidgetType.SPARKLINE_CONSUMPTIONS[0] ? 16 : 8}>
                                                        <Field
                                                            name="measurements"
                                                            label={
                                                                widgetChoice === WidgetType.SPARKLINE_CONSUMPTIONS[0]
                                                                    ? i18n._(t`measurements`)
                                                                    : i18n._(t`measurement`)
                                                            }
                                                            placeholder={
                                                                widgetChoice === WidgetType.SPARKLINE_CONSUMPTIONS[0]
                                                                    ? i18n._(t`select measurements`)
                                                                    : i18n._(t`select one measurement`)
                                                            }
                                                            disabled={disabled || values.sites.length === 0}
                                                            sites_filter={values.sites}
                                                            siteDisabled={true}
                                                            multipleselect={widgetChoice === WidgetType.SPARKLINE_CONSUMPTIONS[0] ? 1 : 0}
                                                            options={measurements.data}
                                                            restricted_mttypes_id={restricted_mttypes_id}
                                                            displayAddBtn={false}
                                                            sites={sites.data}
                                                            usages={usages.data}
                                                            component={DropdownAdapterMeasurement}
                                                            noResultsMessage={i18n._(t`no result found`)}
                                                            open={false}
                                                            isRequired={true}
                                                            validate={(value) => {
                                                                if (!disabled) {
                                                                    if (widgetChoice === WidgetType.SPARKLINE_CONSUMPTION[0] && !value) {
                                                                        return <Trans>Required field</Trans>;
                                                                    }
                                                                    if (widgetChoice === WidgetType.SPARKLINE_CONSUMPTIONS[0] && _.size(value) < 1) {
                                                                        return <Trans>Required field</Trans>;
                                                                    }
                                                                    if (widgetChoice === WidgetType.SPARKLINE_CONSUMPTIONS[0] && _.size(value) > 1) {
                                                                        const measurements_wrap = _.chain(value).reduce((res, measure_id) => {
                                                                            const measure = _.find(measurements.data, { id: measure_id });
                                                                            if (measure) res.push(measure);
                                                                            return res;
                                                                        }, []);

                                                                        const hasMultipleUnit =
                                                                            measurements_wrap.groupBy("display_unit.symbol").size().value() > 1;
                                                                        if (hasMultipleUnit) {
                                                                            return <Trans>Measurements must belong to the same type</Trans>;
                                                                        }
                                                                    }
                                                                }
                                                                return undefined;
                                                            }}
                                                        />
                                                    </Grid.Column>
                                                </Grid.Row>
                                            </Grid>
                                        </Segment>
                                        <Segment attached basic textAlign="right">
                                            <Button type="button" negative onClick={() => onClose()}>
                                                <Trans>cancel</Trans>
                                            </Button>
                                            {!disabled && (
                                                <Button
                                                    type="submit"
                                                    positive
                                                    icon
                                                    labelPosition="right"
                                                    disabled={submitting || pristine || invalid}
                                                >
                                                    <Icon name="check" />
                                                    <Trans>validate</Trans>
                                                </Button>
                                            )}
                                        </Segment>
                                    </form>
                                );
                            }}
                        />
                    );
                }
                return (
                    <>
                        <MyHeader />
                        <MessageDisplay message={i18n._(t`loading data`)} level="info" iconName="circle notched" isLoading={true} />
                        <Segment attached textAlign="right" basic>
                            <Button type="button" negative onClick={() => onClose()}>
                                <Trans>cancel</Trans>
                            </Button>
                        </Segment>
                    </>
                );
            })()}
        </>
    );
};

export default SparklineConsumption;
