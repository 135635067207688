import React from "react";
import _ from "lodash";
import { Trans } from "@lingui/macro";
import { Container, Grid } from "semantic-ui-react";

import i18n from "modules/i18n/i18nConfig";
import { co2ConversionRate, priceConversionRate } from "modules/common/utils";
import { dynamicValueUnit } from "modules/data/utils";

export const EnergyHeaderText = (props) => {
    const { total_consumption, standby_threshold, dataflow } = props;
    let columns = 1;

    const conso = _.get(total_consumption, "data.data", null);
    const unit = _.get(total_consumption, "data.measure.display_unit.symbol", "-");
    const measure = _.get(total_consumption, "data.measure");
    const auto_unit = _.get(measure, "auto_unit", true);

    const { factor, new_unit } = dynamicValueUnit(conso, unit, auto_unit);

    const wrap_df = _.chain(dataflow);
    const site_conversions = wrap_df.get("site.conversions", null).value();
    const category_name = wrap_df.get("dataflowspec.name").value();
    const mt_type_name = _.get(measure, "measurementtype.name");

    const rate_price = priceConversionRate(conso, site_conversions, category_name, mt_type_name, i18n);
    const rate_co2 = co2ConversionRate(conso, site_conversions, category_name, mt_type_name, i18n);

    if (_.get(site_conversions, "currency") !== undefined && rate_price) {
        columns = columns + 1;
    }
    if (rate_co2) {
        columns = columns + 1;
    }
    if (_.isFinite(standby_threshold)) {
        columns = columns + 1;
    }

    return (
        <Container>
            <Grid stackable>
                <Grid.Row columns={columns}>
                    <Grid.Column>
                        <Trans>Period consumption</Trans>
                        {`: ${i18n.number(conso * factor, { maximumFractionDigits: 2 })} ${new_unit}`}
                    </Grid.Column>
                    {_.isFinite(standby_threshold) && (
                        <Grid.Column>
                            <Trans>Standby consumption</Trans>
                            {`: ${i18n.number(standby_threshold * factor, { maximumFractionDigits: 2 })} ${new_unit}`}
                        </Grid.Column>
                    )}
                    {rate_co2 && (
                        <Grid.Column>
                            <Trans>Co2 equivalent</Trans>
                            {` : ${rate_co2}`}
                        </Grid.Column>
                    )}
                    {_.get(site_conversions, "currency") !== undefined && rate_price && (
                        <Grid.Column>
                            <Trans>Cost</Trans>
                            {` : ${rate_price}`}
                        </Grid.Column>
                    )}
                </Grid.Row>
            </Grid>
        </Container>
    );
};

export default EnergyHeaderText;
