import React from "react";
import { useSelector } from "react-redux";
import _ from "lodash";
import { Trans } from "@lingui/macro";
import { Dropdown, Grid, Icon, Input, Label, Popup } from "semantic-ui-react";

import { useGetSitesQuery } from "modules/site/siteService";
import { useGetUsagesQuery } from "modules/usage/usageService";

import { upsert } from "./TreeItem";
import DropdownMeasurement from "./DropdownMeasurement";
import { Media } from "App";

const operatorList = [
    { key: ">", value: ">", text: ">" },
    { key: "<", value: "<", text: "<" }
];

const ThresholdItem = (props) => {
    const { node, tree, updateTree, form, alert_type } = props;
    const { org } = useSelector((state) => state);
    const sites = useGetSitesQuery({ org: org.current }, { skip: !org.current });
    const usages = useGetUsagesQuery({ org: org.current }, { skip: !org.current });
    const { measurements } = useSelector((state) => state.measurement);
    const measure = _.find(measurements, { id: node.measure });

    const onChangeNode = (data, field) => {
        const new_tree = [...tree]; //copy of tree for update
        const current_node = { ...node, [field]: data.value };
        upsert(new_tree, current_node);
        updateTree(new_tree);
    };

    return (
        <Grid verticalAlign="middle">
            <Grid.Column mobile={16} tablet={9} computer={9}>
                <DropdownMeasurement
                    node={node}
                    tree={tree}
                    updateTree={updateTree}
                    form={form}
                    sites={sites.data}
                    usages={usages.data}
                    alert_type={alert_type}
                />
            </Grid.Column>
            <Grid.Column mobile={3} tablet={2} computer={2}>
                <Dropdown
                    fluid
                    selection
                    options={operatorList}
                    onChange={(e, data) => onChangeNode(data, "operator")}
                    value={node.operator}
                    upward
                />
            </Grid.Column>
            <Grid.Column mobile={13} tablet={4} computer={4}>
                <Input
                    fluid
                    inputMode="decimal"
                    onChange={(e, data) => onChangeNode(data, "threshold")}
                    value={node.threshold}
                    labelPosition={node?.measure !== null ? "right" : undefined}
                >
                    <input />
                    {node?.measure != null && _.result(measure, "display_unit", null) !== null && (
                        <Label color="blue">
                            {_.includes([1, 2], alert_type)
                                ? measure?.display_unit?.symbol ?? "-"
                                : measure?.display_unit?.intensive ?? measure?.display_unit?.symbol ?? "-"}
                        </Label>
                    )}
                </Input>
            </Grid.Column>
            {alert_type === 2 && (
                <Grid.Column width={16}>
                    <Grid>
                        <Grid.Column mobile={16} tablet={11} computer={11}>
                            <Input
                                fluid
                                inputMode="decimal"
                                labelPosition="right"
                                onChange={(e, data) => onChangeNode(data, "w_depth")}
                                value={node.w_depth}
                            >
                                <Label basic>
                                    <Media lessThan="tablet">
                                        {(mediaClassNames, renderChildren) =>
                                            renderChildren && (
                                                <Popup
                                                    trigger={
                                                        <Icon.Group size="large">
                                                            <Icon name="clock outline" />
                                                            <Icon name="reply" corner="top right" />
                                                        </Icon.Group>
                                                    }
                                                >
                                                    <Popup.Content>
                                                        <Trans>Window depth</Trans>
                                                    </Popup.Content>
                                                </Popup>
                                            )
                                        }
                                    </Media>
                                    <Media greaterThanOrEqual="tablet">
                                        {(mediaClassNames, renderChildren) => renderChildren && <Trans>Window depth</Trans>}
                                    </Media>
                                </Label>
                                <input />
                                <Label color="blue">
                                    <Trans>min</Trans>
                                </Label>
                            </Input>
                        </Grid.Column>
                    </Grid>
                </Grid.Column>
            )}
        </Grid>
    );
};

export default ThresholdItem;
