import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import _ from "lodash";
import { t, Trans } from "@lingui/macro";
import { Grid, Label, Input, Dropdown, Accordion, Button, Icon } from "semantic-ui-react";

import i18n from "modules/i18n/i18nConfig";
import { removeAccents } from "modules/common/utils";
import {
    setCategoryFilter,
    setReadyFilter,
    setSearchNameFilter,
    setSiteFilter,
    setTagFilter,
    setUsageFilter,
    setZoneFilter,
    setSortByCategory,
    setSortByUnit,
    setSortOrder,
    setReadySort
} from "modules/dashboard/dashboardSlice";
import { sort_list, categories_with_units } from "modules/dashboard/utils";

import { Media } from "App";

let searchTimeout = null;

const DashboardFilter = (props) => {
    const { sites, usages, units } = props;
    const [localSearchName, setLocalSearchName] = useState("");
    const { dashboard, zone, tag, category } = useSelector((state) => state);
    const selectedCategory = useSelector((state) => state.dashboard.sort.category);
    const dispatch = useDispatch();
    const unitsForSelectedCategoryOptions = _.find(categories_with_units, { category: selectedCategory })?.units;

    useEffect(() => {
        setLocalSearchName(dashboard.filter.searchName);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const zoneOptions = _.chain(zone.zones)
        .reduce((res, zone) => {
            if (_.size(dashboard.filter.siteFilter) === 0) {
                res.push(zone);
            } else if (_.includes(dashboard.filter.siteFilter, zone.site_id)) {
                res.push(zone);
            }
            return res;
        }, [])
        .map(({ key, text, value }) => ({ key, text, value, content: <Label color="teal">{i18n._(text)}</Label> }))
        .orderBy((item) => {
            return removeAccents(item.text).toLowerCase();
        }, "asc")
        .value();

    const renderFilter = () => (
        <Grid>
            <Grid.Column mobile={16} tablet={3} computer={3}>
                <Dropdown
                    fluid
                    options={_.chain(sites)
                        .map(({ key, text, value }) => ({
                            key,
                            text,
                            value,
                            content: <Label color="blue">{i18n._(text)}</Label>
                        }))
                        .orderBy((item) => {
                            return removeAccents(item.text).toLowerCase();
                        }, "asc")
                        .value()}
                    placeholder={i18n._(t`select sites`)}
                    multiple
                    selection
                    search
                    onChange={async (e, { value }) => {
                        await dispatch(setReadyFilter(false));
                        await dispatch(setSiteFilter(value));
                        await dispatch(setZoneFilter([]));
                        await dispatch(setReadyFilter(true));
                    }}
                    renderLabel={(label) => ({ color: "blue", content: i18n._(label.text) })}
                    value={dashboard.filter.siteFilter}
                />
            </Grid.Column>
            <Grid.Column mobile={16} tablet={3} computer={3}>
                <Dropdown
                    fluid
                    disabled={_.size(dashboard.filter.siteFilter) === 0}
                    options={zoneOptions}
                    placeholder={i18n._(t`select zones`)}
                    multiple
                    selection
                    search
                    onChange={async (e, { value }) => {
                        await dispatch(setReadyFilter(false));
                        await dispatch(setZoneFilter(value));
                        await dispatch(setReadyFilter(true));
                    }}
                    renderLabel={(label) => ({ color: "teal", content: i18n._(label.text) })}
                    value={dashboard.filter.zoneFilter}
                />
            </Grid.Column>
            <Grid.Column mobile={16} tablet={3} computer={3}>
                <Dropdown
                    fluid
                    options={_.chain(category.categories)
                        .reduce((res, category) => {
                            if (!_.includes([5, 8, 9, 11, 12, 27], category.id)) {
                                const { key, text, value } = category;
                                res.push({
                                    key,
                                    text: i18n._(text),
                                    value,
                                    content: <Label color="purple">{i18n._(text)}</Label>
                                });
                            }
                            return res;
                        }, [])
                        .orderBy((item) => {
                            return removeAccents(item.text).toLowerCase();
                        }, "asc")
                        .value()}
                    placeholder={i18n._(t`select categories`)}
                    multiple
                    selection
                    search
                    onChange={async (e, { value }) => {
                        await dispatch(setReadyFilter(false));
                        await dispatch(setCategoryFilter(value));
                        await dispatch(setReadyFilter(true));
                    }}
                    renderLabel={(label) => ({ color: "purple", content: i18n._(label.text) })}
                    value={dashboard.filter.categoryFilter}
                />
            </Grid.Column>
            <Grid.Column mobile={16} tablet={3} computer={3}>
                <Dropdown
                    fluid
                    options={_.chain(usages)
                        .map(({ key, text, value }) => ({ key, text, value, content: <Label color="violet">{i18n._(text)}</Label> }))
                        .orderBy((item) => {
                            return removeAccents(item.text).toLowerCase();
                        }, "asc")
                        .value()}
                    placeholder={i18n._(t`select usages`)}
                    multiple
                    selection
                    search
                    onChange={async (e, { value }) => {
                        await dispatch(setReadyFilter(false));
                        await dispatch(setUsageFilter(value));
                        await dispatch(setReadyFilter(true));
                    }}
                    renderLabel={(label) => ({ color: "violet", content: i18n._(label.text) })}
                    value={dashboard.filter.usageFilter}
                />
            </Grid.Column>
            <Grid.Column mobile={16} tablet={4} computer={4}>
                <Dropdown
                    fluid
                    options={_.chain(tag.tags)
                        .map(({ key, text, value }) => ({ key, text, value, content: <Label color="grey">{i18n._(text)}</Label> }))
                        .orderBy((item) => {
                            return removeAccents(item.text).toLowerCase();
                        }, "asc")
                        .value()}
                    placeholder={i18n._(t`select tags`)}
                    multiple
                    selection
                    search
                    onChange={async (e, { value }) => {
                        await dispatch(setReadyFilter(false));
                        await dispatch(setTagFilter(value));
                        await dispatch(setReadyFilter(true));
                    }}
                    renderLabel={(label) => ({ color: "grey", content: i18n._(label.text) })}
                    value={dashboard.filter.tagFilter}
                />
            </Grid.Column>
            <Grid.Column mobile={16} computer={9}>
                <Input
                    style={{ height: "2.9rem" }} // Needed to be the same height as the next elements(dropdown)
                    fluid
                    icon="search"
                    placeholder={i18n._(t`search by name`)}
                    onChange={(e, { value }) => {
                        clearTimeout(searchTimeout);
                        setLocalSearchName(value);
                        searchTimeout = setTimeout(async () => {
                            await dispatch(setReadyFilter(false));
                            await dispatch(setSearchNameFilter(value));
                            await dispatch(setReadyFilter(true));
                        }, 1000);
                    }}
                    value={localSearchName}
                />
            </Grid.Column>

            <Grid.Column mobile={16} tablet={7} computer={3}>
                <Dropdown
                    style={{ minHeight: "2.9rem" }}
                    fluid
                    button
                    labeled
                    className="icon"
                    icon="sort"
                    options={_.chain(sort_list)
                        .map(({ key, text, value }) => ({
                            key,
                            text: i18n._(text),
                            value
                        }))
                        .sort((a, b) => a.text.localeCompare(b.text, i18n.language))
                        .value()}
                    placeholder={i18n._(t`Select types`)}
                    selection
                    onChange={async (e, { value }) => {
                        await dispatch(async (dispatch, getState) => {
                            dispatch(setReadySort(false));
                            if (value === 2 || value === 3 || value === 4) {
                                dispatch(setSortOrder(false));
                                dispatch(setSortByUnit("kWh"));
                            }
                            if (value === 8 || value === 7) {
                                dispatch(setSortOrder(true));
                            } else {
                                dispatch(setSortOrder(false));
                            }
                            dispatch(setSortByCategory(value));
                            dispatch(setReadySort(true));
                        });
                    }}
                    value={dashboard.sort.category}
                />
            </Grid.Column>
            <Grid.Column mobile={13} tablet={7} computer={3}>
                <Dropdown
                    disabled={_.includes([-1, 1, 5, 6, 7, 8], dashboard.sort.category)}
                    style={{ height: "2.9rem" }}
                    fluid
                    button
                    labeled
                    className="icon"
                    icon="sort"
                    options={_.chain([
                        ...units,
                        {
                            id: 666,
                            key: 666,
                            text: "healthscore",
                            value: 666,
                            base: null,
                            type: "unit"
                        }
                    ])
                        .reduce((res, unit) => {
                            if (_.includes(unitsForSelectedCategoryOptions, unit.id)) {
                                const { key, text, intensive } = unit;
                                res.push({
                                    key,
                                    text: dashboard.sort.category === 2 && intensive ? i18n._(intensive) : i18n._(text),
                                    value: text
                                });
                            }
                            return res;
                        }, [])
                        .sort((a, b) => a.text.localeCompare(b.text, i18n.language))
                        .value()}
                    placeholder={_.includes([1, 5, 6, 7, 8], dashboard.sort.category) ? i18n._(t`No unit required`) : i18n._(t`Select Unit`)}
                    selection
                    onChange={async (e, { value }) => {
                        await dispatch(setReadyFilter(false));
                        await dispatch(setSortByUnit(value));
                        await dispatch(setReadyFilter(true));
                    }}
                    value={dashboard.sort.unit}
                />
            </Grid.Column>
            <Grid.Column mobile={3} tablet={2} computer={1}>
                <Button
                    fluid
                    style={{ height: "2.9rem" }}
                    icon
                    basic
                    onClick={async () => {
                        await dispatch(setReadyFilter(false));
                        await dispatch(setSortOrder(!dashboard.sort.order));
                        await dispatch(setReadyFilter(true));
                    }}
                >
                    {dashboard.sort.order && (
                        <>
                            <Icon style={{ paddingRight: "1rem" }} name="sort amount down" />
                            <Trans> Asc</Trans>
                        </>
                    )}
                    {!dashboard.sort.order && (
                        <>
                            <Icon style={{ paddingRight: "1rem" }} name="sort amount up" />
                            <Trans> Desc</Trans>
                        </>
                    )}
                </Button>
            </Grid.Column>
        </Grid>
    );

    return (
        <Grid.Row>
            <Grid.Column width={16}>
                <Media lessThan="computer">
                    {(mediaClassNames, renderChildren) =>
                        renderChildren && (
                            <Accordion
                                panels={[
                                    {
                                        key: "filters",
                                        title: i18n._(t`filters`),
                                        content: { content: renderFilter() }
                                    }
                                ]}
                            />
                        )
                    }
                </Media>
                <Media greaterThanOrEqual="computer">{(mediaClassNames, renderChildren) => renderChildren && renderFilter()}</Media>
            </Grid.Column>
        </Grid.Row>
    );
};

export default DashboardFilter;
