import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { t, Trans } from "@lingui/macro";
import _ from "lodash";
import { Grid, Dropdown, Modal, Button, Accordion, Table, Segment, Input, Label } from "semantic-ui-react";

import i18n from "modules/i18n/i18nConfig";
import { upsert } from "./TreeItem";
import { removeAccents } from "modules/common/utils";

import MessageDisplay from "modules/common/components/MessageDisplay";
import GenericPaginate from "modules/common/components/GenericPaginate";
import { Media } from "App";

const DropdownMeasurement = (props) => {
    const { node, tree, updateTree, form, sites, usages, alert_type } = props;
    //Current measure
    const [selectedMeasure, setSelectedMeasure] = useState(node.measure);

    //Pagination
    const [open, setOpen] = useState(false);
    const [page, setPage] = useState(0);
    const [rowPerPage, setRowPerPage] = useState(5);
    //Filter
    const [searchName, setSearchName] = useState("");
    const [siteFilter, setSiteFilter] = useState([]);
    const [zoneFilter, setZoneFilter] = useState([]);
    const [categoryFilter, setCategoryFilter] = useState([]);
    const [usageFilter, setUsageFilter] = useState([]);
    const [tagFilter, setTagFilter] = useState([]);
    const [mttypeFilter, setMttypeFilter] = useState([]);

    const { zone, tag, category, measurement, measurementtype, dataflow, equipment } = useSelector((state) => state);

    useEffect(() => {
        if (open) {
            const site = _.get(form.getFieldState("site"), "value");
            site && setSiteFilter([site]);
            //reset filter && page
            setZoneFilter([]);
            setCategoryFilter([]);
            setUsageFilter([]);
            setTagFilter([]);
            setMttypeFilter([]);
            setPage(0);
            setRowPerPage(5);
            setSelectedMeasure(node.measure);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [open]);

    useEffect(() => {
        setPage(0);
    }, [searchName, siteFilter, zoneFilter, categoryFilter, usageFilter, tagFilter, mttypeFilter]);

    const zoneOptions = _.chain(zone.zones)
        .reduce((res, zone) => {
            if (_.size(siteFilter) === 0) {
                res.push(zone);
            } else if (_.includes(siteFilter, zone.site_id)) {
                res.push(zone);
            }
            return res;
        }, [])
        .map(({ key, text, value }) => ({ key, text, value, content: <Label color="teal">{text}</Label> }))
        .value();

    const filters = (
        <Grid verticalAlign="middle" centered>
            <Grid.Column width={16}>
                <Input
                    fluid
                    icon="search"
                    placeholder={i18n._(t`search identifier`)}
                    onChange={(e, { value }) => {
                        setSearchName(value);
                    }}
                    value={searchName}
                />
            </Grid.Column>
            <Grid.Column mobile={16} tablet={8} computer={8}>
                <Dropdown
                    fluid
                    options={_.map(sites, ({ key, text, value }) => ({ key, text, value, content: <Label color="blue">{text}</Label> }))}
                    placeholder={i18n._(t`select sites`)}
                    multiple
                    selection
                    onChange={(e, { value }) => {
                        setSiteFilter(value);
                        setZoneFilter([]);
                    }}
                    renderLabel={(label) => ({ color: "blue", content: label.text })}
                    value={siteFilter}
                    disabled={true}
                />
            </Grid.Column>
            <Grid.Column mobile={16} tablet={8} computer={8}>
                <Dropdown
                    fluid
                    disabled={_.size(siteFilter) === 0}
                    options={zoneOptions}
                    placeholder={i18n._(t`select zones`)}
                    multiple
                    selection
                    onChange={(e, { value }) => {
                        setZoneFilter(value);
                    }}
                    renderLabel={(label) => ({ color: "teal", content: label.text })}
                    value={zoneFilter}
                />
            </Grid.Column>
            <Grid.Column mobile={16} tablet={4} computer={4}>
                <Dropdown
                    fluid
                    options={_.chain(category.categories)
                        .reduce((res, category) => {
                            if (!_.includes([5, 9, 11, 12, 27], category.id)) {
                                const { key, text, value } = category;
                                res.push({
                                    key,
                                    text: i18n._(text),
                                    value,
                                    content: <Label color="purple">{i18n._(text)}</Label>
                                });
                            }
                            return res;
                        }, [])
                        .orderBy((item) => {
                            return removeAccents(item.text).toLowerCase();
                        }, "asc")
                        .value()}
                    placeholder={i18n._(t`select categories`)}
                    multiple
                    selection
                    onChange={(e, { value }) => {
                        setCategoryFilter(value);
                    }}
                    renderLabel={(label) => ({ color: "purple", content: label.text })}
                    value={categoryFilter}
                />
            </Grid.Column>
            <Grid.Column mobile={16} tablet={4} computer={4}>
                <Dropdown
                    fluid
                    options={_.chain(measurementtype.measurementtypes)
                        .filter((item) => !_.includes([22, 19, 18, 8, 26, 13, 11, 14, 60], item.id)) // Remove MT_TYPE
                        .filter((item) => {
                            //Remove electical index act/react in measurement type selector. Prevent text duplication
                            return !_.includes(["e_act_counter", "e_react+_counter", "e_react-_counter"], item.text);
                        })
                        .map(({ key, text, value }) => ({ key, text: i18n._(text), value, content: <Label color="yellow">{i18n._(text)}</Label> }))
                        .orderBy((item) => {
                            return removeAccents(item.text).toLowerCase();
                        }, "asc")
                        .value()}
                    placeholder={i18n._(t`select measurementtypes`)}
                    multiple
                    selection
                    onChange={(e, { value }) => {
                        setMttypeFilter(value);
                    }}
                    renderLabel={(label) => ({ color: "yellow", content: label.text })}
                    value={mttypeFilter}
                />
            </Grid.Column>
            <Grid.Column mobile={16} tablet={4} computer={4}>
                <Dropdown
                    fluid
                    options={_.map(usages, ({ key, text, value }) => ({ key, text, value, content: <Label color="violet">{text}</Label> }))}
                    placeholder={i18n._(t`select usages`)}
                    multiple
                    selection
                    onChange={(e, { value }) => {
                        setUsageFilter(value);
                    }}
                    renderLabel={(label) => ({ color: "violet", content: label.text })}
                    value={usageFilter}
                />
            </Grid.Column>
            <Grid.Column mobile={16} tablet={4} computer={4}>
                <Dropdown
                    fluid
                    options={_.map(tag.tags, ({ key, text, value }) => ({ key, text, value, content: <Label color="grey">{text}</Label> }))}
                    placeholder={i18n._(t`select tags`)}
                    multiple
                    selection
                    onChange={(e, { value }) => {
                        setTagFilter(value);
                    }}
                    renderLabel={(label) => ({ color: "grey", content: label.text })}
                    value={tagFilter}
                />
            </Grid.Column>
        </Grid>
    );

    /******  PAGINATION BLOCK ******/

    const onHandlePageChange = (event, data) => {
        // Pagination activePage value
        const { activePage } = data;
        setPage(activePage - 1);
    };

    const onHandleChangeItemPageSize = (event, data) => {
        const { value } = data;
        setPage(0);
        setRowPerPage(parseInt(value));
    };

    const submitMeasurements = () => {
        if (selectedMeasure) {
            const new_tree = [...tree]; //copy of tree for update
            const current_node = { ...node, measure: selectedMeasure };
            upsert(new_tree, current_node);
            updateTree(new_tree);
        }
        setOpen(false);
    };

    const measures =
        _.chain(measurement.measurements)
            .filter((measure) => {
                if (_.includes([1, 2], alert_type)) {
                    if (measure?.measurementtype?.datapoint_type === 3) {
                        return true;
                    }
                    return false;
                }
                return true;
            })
            .map((measure) => {
                const df = _.find(dataflow.dataflows, { id: measure.dataflow }) || null;
                const eqpt = _.find(equipment.equipments, { id: _.get(df, "equipment", null) });
                return {
                    ...measure,
                    dataflow: df,
                    equipment: eqpt
                };
            })
            .reduce((res, measure) => {
                if (searchName === "") {
                    res.push(measure);
                } else if (measure.dataflow && _.includes(measure.dataflow.name.toLowerCase(), searchName.toLowerCase())) {
                    res.push(measure);
                }
                return res;
            }, [])
            .reduce((res, measure) => {
                if (_.size(siteFilter) === 0) {
                    res.push(measure);
                } else if (_.includes(siteFilter, _.get(measure, "dataflow.site.id", null))) {
                    res.push(measure);
                }
                return res;
            }, [])
            .reduce((res, measure) => {
                if (_.size(zoneFilter) === 0) {
                    res.push(measure);
                } else if (_.includes(zoneFilter, _.get(measure, "dataflow.zone.id", null))) {
                    res.push(measure);
                }
                return res;
            }, [])
            .reduce((res, measure) => {
                /* Tags filter */
                if (_.size(tagFilter) === 0) {
                    res.push(measure);
                    return res;
                }
                const count_tags = _.chain(measure)
                    .get("dataflow.tag_set")
                    .map((tag) => {
                        if (_.includes(tagFilter, tag.id)) return 1;
                        return 0;
                    })
                    .sum()
                    .value();
                if (count_tags) {
                    res.push(measure);
                }
                return res;
            }, [])
            .reduce((res, measure) => {
                if (_.size(usageFilter) === 0) {
                    res.push(measure);
                } else if (_.includes(usageFilter, _.get(measure, "dataflow.usage.id", null))) {
                    res.push(measure);
                }
                return res;
            }, [])
            .reduce((res, measure) => {
                if (_.size(categoryFilter) === 0) {
                    res.push(measure);
                } else if (_.includes(categoryFilter, _.get(measure, "dataflow.dataflowspec.id", null))) {
                    res.push(measure);
                }
                return res;
            }, [])
            .reduce((res, measure) => {
                if (_.size(mttypeFilter) === 0) {
                    res.push(measure);
                    return res;
                } else if (_.includes(mttypeFilter, _.get(measure, "measurementtype.id", null))) {
                    res.push(measure);
                }
                return res;
            }, [])
            .value() || [];

    return (
        <>
            <Dropdown
                fluid
                open={false}
                selection
                search
                options={_.map(measurement.measurements, ({ key, text, value }) => ({ key, text, value }))}
                value={node.measure}
                icon={null}
                onFocus={(event, data) => {
                    setOpen(true);
                }}
                style={{ overflow: "hidden", textOverflow: "ellipsis", whiteSpace: "nowrap" }}
            />
            <Modal open={open}>
                <Modal.Header>
                    <Trans>choose your measurement</Trans>
                </Modal.Header>
                <Modal.Content scrolling={false}>
                    <Media lessThan="computer">
                        {(mediaClassNames, renderChildren) =>
                            renderChildren && (
                                <Segment id="pwaControls" attached="top">
                                    <Accordion
                                        panels={[
                                            {
                                                key: "filters",
                                                title: i18n._(t`filters`),
                                                content: { content: filters }
                                            }
                                        ]}
                                    />
                                </Segment>
                            )
                        }
                    </Media>
                    <Media greaterThanOrEqual="computer">
                        {(mediaClassNames, renderChildren) =>
                            renderChildren && (
                                <Segment id="pwaControls" attached="top">
                                    {filters}
                                </Segment>
                            )
                        }
                    </Media>
                    {_.size(measures) === 0 && (
                        <MessageDisplay message={i18n._(t`no data criteria`)} level="warning" iconName="warning circle" isLoading={false} />
                    )}
                    <div style={{ overflowX: "auto" }}>
                        <Table striped celled unstackable compact>
                            <Table.Header>
                                <Table.Row>
                                    <Table.HeaderCell colSpan="2" textAlign="right">
                                        <GenericPaginate
                                            rowsLength={_.size(measures)}
                                            rowPerPage={rowPerPage}
                                            page={page}
                                            onPageChange={onHandlePageChange}
                                            onChangeItemPageSize={onHandleChangeItemPageSize}
                                        />
                                    </Table.HeaderCell>
                                </Table.Row>
                            </Table.Header>
                            <Table.Body>
                                {measures.slice(page * rowPerPage, page * rowPerPage + rowPerPage).map((item, idx) => {
                                    return (
                                        <Table.Row key={idx} style={{ cursor: "pointer" }}>
                                            <Table.Cell
                                                positive={selectedMeasure === item.id}
                                                onClick={(e) => {
                                                    setSelectedMeasure(item.id);
                                                }}
                                            >
                                                {item.text}
                                            </Table.Cell>
                                        </Table.Row>
                                    );
                                })}
                            </Table.Body>
                        </Table>
                    </div>
                </Modal.Content>
                <Modal.Actions>
                    {selectedMeasure === null && (
                        <Segment basic textAlign="center">
                            <MessageDisplay message={i18n._(t`select 1 measure`)} level="info" iconName="info circle" isLoading={false} />
                        </Segment>
                    )}
                    <Button
                        type="button"
                        negative
                        onClick={(e, data) => {
                            setOpen(false);
                        }}
                    >
                        <Trans>cancel</Trans>
                    </Button>
                    <Button
                        type="button"
                        positive
                        labelPosition="right"
                        icon="checkmark"
                        content={i18n._(t`validate`)}
                        onClick={(e) => {
                            e.preventDefault();
                            submitMeasurements();
                        }}
                        disabled={props.node.measure === selectedMeasure}
                    />
                </Modal.Actions>
            </Modal>
        </>
    );
};

export default DropdownMeasurement;
