import React from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router";
import _ from "lodash";
import { t, Trans } from "@lingui/macro";
import { Grid, Segment, Header } from "semantic-ui-react";

import i18n from "modules/i18n/i18nConfig";
import { useGetSitesQuery } from "modules/site/siteService";
import { useGetMeasurementsQuery } from "modules/measurement/measurementService";
import { useGetEquipmentQuery, useGetEquipmentsQuery } from "modules/equipment/equipmentService";
import { useGetUnitsQuery } from "modules/unit/unitService";
import { useGetCategoriesQuery } from "modules/category/categoryService";
import { useGetZonesQuery } from "modules/area/areaService";
import { useGetUsagesQuery } from "modules/usage/usageService";
import { useGetTagsQuery } from "modules/tag/tagService";
import { useGetMeasurementtypesQuery } from "modules/measurement/measurementtypeService";
import { useGetDataflowsQuery } from "modules/dataflow/dataflowService";
import { useGetEquipmenttypesQuery } from "modules/equipment/equipmenttypeService";
import { useGetSensortypesQuery } from "modules/sensor/sensorTypeService";
import { useGetMachinesQuery } from "modules/machine/machineService";
import { useGetMachinetypesQuery } from "modules/machine/machinetypeService";
import { useGetComponentsQuery } from "modules/machine/componentService";
import { useGetHealthscoretypesQuery } from "modules/machine/healthscoretypeService";

import MessageDisplay from "modules/common/components/MessageDisplay";
import Back from "modules/common/components/back";
import { Media } from "App";
import Infos from "modules/dashboard/components/widgets/Infos";
import DispoInfo from "modules/dashboard/components/widgets/DispoInfo";
import OverviewTab from "./OverviewTab";
import { mt_type_blacklist } from "../utils";

const Overview2 = (props) => {
    const { id_eqpmt } = useParams();
    const org = useSelector((state) => state.org);
    const sites = useGetSitesQuery({ org: org.current }, { skip: !org.current || !id_eqpmt });
    const equipments = useGetEquipmentsQuery({ org: org.current }, { skip: !org.current || !id_eqpmt });
    const units = useGetUnitsQuery({ org: org.current }, { skip: !org.current || !id_eqpmt });
    const categories = useGetCategoriesQuery({ org: org.current }, { skip: !org.current || !id_eqpmt });
    const zones = useGetZonesQuery({ org: org.current }, { skip: !org.current || !id_eqpmt });
    const usages = useGetUsagesQuery({ org: org.current }, { skip: !org.current || !id_eqpmt });
    const tags = useGetTagsQuery({ org: org.current }, { skip: !org.current || !id_eqpmt });
    const measurementtypes = useGetMeasurementtypesQuery({ org: org.current }, { skip: !org.current || !id_eqpmt });
    const equipmenttypes = useGetEquipmenttypesQuery({ org: org.current }, { skip: !org.current || !id_eqpmt });
    const sensortypes = useGetSensortypesQuery({ org: org.current }, { skip: !org.current || !id_eqpmt });
    const machines = useGetMachinesQuery({ org: org.current }, { skip: !org.current || !id_eqpmt });
    const dataflows = useGetDataflowsQuery(
        {
            org: org.current,
            categories: categories.data,
            tags: tags.data,
            sites: sites.data,
            zones: zones.data,
            usages: usages.data
        },
        {
            skip:
                !org.current ||
                categories.data === undefined ||
                tags.data === undefined ||
                sites.data === undefined ||
                zones.data === undefined ||
                usages.data === undefined ||
                !id_eqpmt
        }
    );
    const measurements = useGetMeasurementsQuery(
        { org: org.current, dataflows: dataflows.data, measurementtypes: measurementtypes.data, units: units.data, equipments: equipments.data },
        {
            skip: !org.current || equipments.data === undefined || dataflows.data === undefined || measurementtypes.data === undefined,
            units: units.data || !id_eqpmt
        }
    );

    //get info for specific equipment
    const equipment = useGetEquipmentQuery(
        { org: org.current, data: id_eqpmt },
        { refetchOnMountOrArgChange: true, skip: !org.current || !id_eqpmt }
    );
    //trigger request only if equipment has machine
    const machinetypes = useGetMachinetypesQuery({ org: org.current }, { skip: !org.current || !_.isFinite(equipment.data?.machine?.id) });
    const components = useGetComponentsQuery({ org: org.current }, { skip: !org.current || !_.isFinite(equipment.data?.machine?.id) });
    const healthscoretypes = useGetHealthscoretypesQuery({ org: org.current }, { skip: !org.current || !_.isFinite(equipment.data?.machine?.id) });

    if (!id_eqpmt) {
        return <MessageDisplay message={i18n._(t`unknow equipment`)} level="warning" iconName="warning circle" isLoading={true} />;
    }

    let error_list = [
        measurements.isError,
        measurementtypes.isError,
        dataflows.isError,
        equipments.isError,
        equipmenttypes.isError,
        sites.isError,
        zones.isError,
        usages.isError,
        categories.isError,
        tags.isError,
        machines.isError,
        units.isError,
        sensortypes.isError
    ];

    if (_.isFinite(equipment.data?.machine)) {
        error_list = [...error_list, machinetypes.isError, components.isError, healthscoretypes.isError];
    }

    let status_list = [
        machines.isSuccess,
        measurements.isSuccess,
        measurementtypes.isSuccess,
        sites.isSuccess,
        zones.isSuccess,
        usages.isSuccess,
        tags.isSuccess,
        units.isSuccess,
        dataflows.isSuccess,
        equipments.isSuccess,
        equipmenttypes.isSuccess,
        categories.isSuccess,
        sensortypes.isSuccess
    ];

    if (_.isFinite(equipment.data?.machine)) {
        status_list = [...status_list, machinetypes.isSuccess, components.isSuccess, healthscoretypes.isSuccess];
    }

    if (_.some([...error_list, equipment.isError])) {
        return (
            <Segment attached>
                <Grid centered verticalAlign="middle">
                    <Grid.Row stretched verticalAlign="middle" className="pwaModuleHeader">
                        <Grid.Column width={2}>
                            <Back />
                        </Grid.Column>
                        <Grid.Column width={12} textAlign="center">
                            <Header as="h1">
                                <Trans>overview</Trans>
                            </Header>
                        </Grid.Column>
                        <Grid.Column width={2}></Grid.Column>
                    </Grid.Row>
                    <Grid.Row>
                        <Grid.Column width={15}>
                            <MessageDisplay
                                message={i18n._(t`error loading data`)}
                                level="error"
                                iconName="warning circle"
                                isLoading={false}
                                attached={false}
                            />
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
            </Segment>
        );
    }

    if (_.every([...status_list, equipment.isSuccess])) {
        const machine = _.find(machines.data, { id: equipment.data?.machine?.id }) || null;
        const machine_type = _.find(machinetypes.data, { id: machine?.machine_type }) || null;
        const comps = _.filter(components.data, { machine: machine?.id }) || [];
        const equipment_type = _.find(equipmenttypes.data, { id: equipment.data?.equipment_type });
        const dfs = _.filter(dataflows.data, (item) => {
            return item?.equipment === equipment.data?.id;
        });
        const measures = _.filter(measurements.data, (item) => {
            if (_.includes(mt_type_blacklist, item?.measurementtype?.name)) return false;
            return item?.equipment_id === equipment.data?.id;
        });

        //transform equipment to put all extra info like machine && component
        const full_equipment = {
            ...equipment.data,
            equipment_type,
            machine: machine && {
                ...machine,
                machine_type,
                components: comps
            }
        };

        return (
            <Segment attached>
                <Grid centered verticalAlign="middle">
                    <Grid.Row>
                        <Grid.Column width={2}>
                            <Back />
                        </Grid.Column>
                        <Grid.Column width={8} textAlign="center">
                            <Header as="h2">{equipment.data?.name ?? "-"}</Header>
                        </Grid.Column>
                        {/* ----- infos column ----- */}
                        {/* mobile */}
                        <Media lessThan="computer">
                            {(mediaClassNames, renderChildren) =>
                                renderChildren && (
                                    <Grid.Column mobile={5} floated="right" className="unpadded print-hidden">
                                        <Segment compact basic floated="right" className="nomargin">
                                            <Infos equipment={equipment} display="button" />
                                            <DispoInfo equipment={equipment.data} display="button" equipment_list={equipment.data?.id?.toString()} />
                                        </Segment>
                                    </Grid.Column>
                                )
                            }
                        </Media>
                        {/* computer or wider */}
                        <Media greaterThanOrEqual="computer">
                            {(mediaClassNames, renderChildren) =>
                                renderChildren && (
                                    <Grid.Column computer={4} textAlign="right" className="no-print">
                                        <Infos equipment={equipment.data} display="label" />
                                    </Grid.Column>
                                )
                            }
                        </Media>
                        <Media greaterThanOrEqual="computer">
                            {(mediaClassNames, renderChildren) =>
                                renderChildren && (
                                    <Grid.Column computer={2} className="print-hidden" textAlign="center">
                                        <DispoInfo equipment={equipment.data} display="button" equipment_list={equipment.data?.id?.toString()} />
                                    </Grid.Column>
                                )
                            }
                        </Media>
                    </Grid.Row>
                    <Grid.Row>
                        <Grid.Column width={16} textAlign="center">
                            <OverviewTab equipment={full_equipment} dataflows={dfs} measurements={measures} />
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
            </Segment>
        );
    }

    return <MessageDisplay message={i18n._(t`loading data`)} level="info" iconName="circle notched" isLoading={true} attached={false} />;
};

export default Overview2;
