import React, { useEffect, useMemo, useRef, useState } from "react";
import { useSelector } from "react-redux";
import _ from "lodash";
import { t } from "@lingui/macro";
import axios from "axios";
import { Grid, Segment } from "semantic-ui-react";

import PowerAdapt, { refreshTimestampUrlParam } from "apis/PowerAdapt";
import i18n from "modules/i18n/i18nConfig";

import Name from "./widgets/Name";
import { Media } from "App";
import Details from "./widgets/Details";
import Infos from "./widgets/Infos";
import DispoInfo from "./widgets/DispoInfo";
import MessageDisplay from "modules/common/components/MessageDisplay";
import DigitalInput from "./widgets/DigitalInput";
import PredictPanel from "./PredictPanel";
import PowerPanel from "./PowerPanel";

const mt_type_blacklist = [
    "battery",
    "battery_status",
    "current_imbalance",
    "cycles",
    "debit_nm3",
    "debit_tv",
    "e_react+_counter",
    "e_react-_counter",
    "i0_ratio",
    "i_moy_max",
    "i_moy_max_ph1",
    "i_moy_max_ph2",
    "i_moy_max_ph3",
    "i_peak",
    "operating_hours",
    "p_act",
    "p_react",
    "p_react+",
    "p_react-",
    "p_react_import",
    "p_react_import+",
    "p_react_import-",
    "power_imbalance",
    "pulse",
    "rotation_speed",
    "sag",
    "sag_ph1",
    "sag_ph2",
    "sag_ph3",
    "swell",
    "swell_ph1",
    "swell_ph2",
    "swell_ph3",
    "thd",
    "thd_ph1",
    "thd_ph2",
    "thd_ph3",
    "trip",
    "voltage_detected",
    "voltage_imbalance",
    "water"
];

const EquipmentRow = (props) => {
    const isMounted = useRef(false);
    const { equipment, machines, equipment_list } = props;
    const { measurement, dataflow, org } = useSelector((state) => state);
    const [lastValues, setLastValues] = useState({ status: "idle", error: null, data: { dataflows: [], machine: null } });
    const current_org = _.get(org, "current.name", null);

    useEffect(() => {
        isMounted.current = true;
        return () => {
            isMounted.current = false;
        };
    }, []);

    const { eqt_measurements, power_measure, measureDigital, hasDigitalInput } = useMemo(() => {
        //Here we process measurements without dataflow consideration. So care for pe6 expert vs non expert
        //Dataflow processing below filter non expert if expert exists but not measurements
        const wrap_measurements = _.chain(measurement.measurements)
            .filter((measure) => !_.includes(mt_type_blacklist, _.get(measure, "measurementtype.name")))
            .filter((measure) => measure.equipment_id === equipment.id);
        const measureDigital = wrap_measurements
            .find((measure) => {
                return _.get(measure, "measurementtype.name") === "di";
            })
            .value();
        return {
            eqt_measurements: wrap_measurements.value(),
            power_measure: wrap_measurements
                .find((measure) => {
                    return _.get(measure, "measurementtype.name") === "p_act_import";
                })
                .value(),
            hasDigitalInput: wrap_measurements.size().value() === 1 && !_.isUndefined(measureDigital),
            measureDigital
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const { eqt_dfs, dataflow_list, eqt_machine } = useMemo(() => {
        const eqt_dfs = _.chain(dataflow)
            .get("dataflows", [])
            .reduce((res, df) => {
                if (df.equipment === equipment.id) res.push(df);
                return res;
            }, [])
            .filter((df, idx, dfs) => {
                if (df?.dataflowspec_tech?.name === "elec") {
                    //remove non-expert dataflow if expert dataflow exists
                    const expert = _.find(dfs, (item) => {
                        return item?.dataflowspec_tech?.name === "elec_expert";
                    });
                    if (expert) return false;
                }
                return true;
            })
            .value();
        const dataflow_list = _.chain(eqt_dfs)
            .map((df) => df.id)
            .toString()
            .value();
        const eqt_machine = _.find(machines, { id: equipment.machine });

        return { eqt_dfs, dataflow_list, eqt_machine };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const getLastValue = async (current_org, dataflow_list, equipment) => {
        try {
            isMounted.current && (await setLastValues({ status: "loading", error: null, data: [] }));
            const response_dfs =
                (equipment.machine === null || !_.isUndefined(power_measure)) &&
                (await PowerAdapt.get(`/dataflows/last_values?org=${current_org}&pk_list=${dataflow_list}&${refreshTimestampUrlParam()}`));
            if (eqt_machine && eqt_machine?.is_predict) {
                const response_machine = await PowerAdapt.get(
                    `/machines/last_values?org=${current_org}&pk_list=${equipment.machine}&${refreshTimestampUrlParam()}`
                );
                isMounted.current &&
                    (await setLastValues({
                        status: "succeeded",
                        error: null,
                        data: { dataflows: _.get(response_dfs, "data", []), machine: response_machine.data }
                    }));
            } else {
                isMounted.current &&
                    (await setLastValues({ status: "succeeded", error: null, data: { dataflows: _.get(response_dfs, "data", []), machine: null } }));
            }
        } catch (error) {
            isMounted.current &&
                (await setLastValues({
                    status: "failed",
                    error: axios.isAxiosError(error) ? error.message : "An unexpected error occurred",
                    data: []
                }));
        }
    };

    useEffect(() => {
        if (!_.isEmpty(dataflow_list)) getLastValue(current_org, dataflow_list, equipment);
        const timer = setInterval(() => {
            if (!_.isEmpty(dataflow_list)) getLastValue(current_org, dataflow_list, equipment);
        }, 60 * 10 * 1000);
        return () => {
            clearInterval(timer);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dataflow_list]);

    return (
        <>
            <Grid.Column mobile={16} computer={13}>
                <Grid verticalAlign="middle" centered padded="vertically">
                    <Grid.Row>
                        <Grid.Column mobile={10} tablet={8} computer={7}>
                            <Name equipment={equipment} />
                        </Grid.Column>
                        <Media lessThan="tablet">
                            {(mediaClassNames, renderChildren) =>
                                renderChildren && (
                                    <Grid.Column mobile={6} floated="right" className="unpadded">
                                        <Segment compact basic floated="right" className="nomargin">
                                            <Details equipment={equipment} display="button" />
                                            <Infos equipment={equipment} display="button" />
                                            <DispoInfo equipment={equipment} display="button" equipment_list={equipment_list} />
                                        </Segment>
                                    </Grid.Column>
                                )
                            }
                        </Media>
                        {/* tablet or wider */}

                        <Media between={["tablet", "computer"]}>
                            {(mediaClassNames, renderChildren) =>
                                renderChildren && (
                                    <Grid.Column tablet={4} computer={7}>
                                        <Segment compact basic floated="right">
                                            <Infos equipment={equipment} display="button" />
                                            <DispoInfo equipment={equipment} display="button" equipment_list={equipment_list} />
                                        </Segment>
                                    </Grid.Column>
                                )
                            }
                        </Media>
                        {/* wide screens */}
                        <Media greaterThanOrEqual="tablet">
                            {(mediaClassNames, renderChildren) =>
                                renderChildren && (
                                    <Grid.Column tablet={4} computer={2} floated="right" className="unpadded">
                                        {_.chain([16]).intersection(_.get(equipment, "dataflowspec_set", [])).size().value() > 0 ? (
                                            <Details equipment={equipment} display="label" text="historic_data" />
                                        ) : (
                                            <Details equipment={equipment} display="label" />
                                        )}
                                    </Grid.Column>
                                )
                            }
                        </Media>
                        {/* tablet or computer */}
                        <Media greaterThanOrEqual="computer">
                            {(mediaClassNames, renderChildren) =>
                                renderChildren && (
                                    <Grid.Column largeScreen={7} widescreen={7} textAlign="right">
                                        <Infos equipment={equipment} display="label" />
                                    </Grid.Column>
                                )
                            }
                        </Media>
                    </Grid.Row>
                    <Grid.Row>
                        <Grid.Column width={16}>
                            {_.isEmpty(eqt_measurements) && (
                                <MessageDisplay message={i18n._(t`no measurements`)} level="warning" iconName="warning circle" isLoading={false} />
                            )}
                            {!_.isEmpty(eqt_measurements) && (
                                <Grid celled stretched centered className={hasDigitalInput ? "pwaPanelDi" : "pwaPanel"}>
                                    <Grid.Row>
                                        {/* Display Row for digital Input */}
                                        {hasDigitalInput && <DigitalInput measure={measureDigital} lastValues={lastValues} />}
                                        {/* Display Predict Row if equipment has machine */}
                                        {eqt_machine && eqt_machine?.is_predict === true && (
                                            <PredictPanel
                                                equipment={{ ...equipment, measurements: eqt_measurements, dataflows: eqt_dfs }}
                                                lastValues={lastValues}
                                            />
                                        )}
                                        {/* Default Display 'Power' Row */}
                                        {(_.isNull(equipment.machine) || (eqt_machine && eqt_machine?.is_predict !== true)) && !hasDigitalInput && (
                                            <PowerPanel
                                                equipment={{ ...equipment, measurements: eqt_measurements, dataflows: eqt_dfs }}
                                                eqt_machine={eqt_machine}
                                                lastValues={lastValues}
                                            />
                                        )}
                                    </Grid.Row>
                                </Grid>
                            )}
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
            </Grid.Column>
            <Media greaterThanOrEqual="computer">
                {(mediaClassNames, renderChildren) =>
                    renderChildren && (
                        <Grid.Column computer={3}>
                            <DispoInfo equipment={equipment} equipment_list={equipment_list} />
                        </Grid.Column>
                    )
                }
            </Media>
        </>
    );
};

export default EquipmentRow;
