import React from "react";
import _ from "lodash";
import { v4 as uuidv4 } from "uuid";
import { Trans } from "@lingui/macro";

import TreeItem from "./TreeItem";

const defaultTree = [{ id: uuidv4(), type: null, children: [] }];

const Tree = (props) => {
    const { form, input } = props;
    const tree = input.value ? input.value : defaultTree;

    //const [tree, setTree] = useState(defaultTree);
    const root = _.head(tree);

    const updateFormWithTree = (tree) => {
        form.change("conditions", tree);
    };

    return (
        <>
            <label>
                <b>
                    <Trans>conditions</Trans>
                </b>
            </label>
            <TreeItem key={root?.id} node={root} tree={tree} updateTree={updateFormWithTree} root={true} meta={props.meta} form={form} />
        </>
    );
};

export default React.memo(Tree);
