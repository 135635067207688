import React, { useMemo } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { Form, Field } from "react-final-form";
import _ from "lodash";
import { Grid, Header, Segment, Button, Container } from "semantic-ui-react";
import { t, Trans } from "@lingui/macro";

import { createAlertPack, updateAlertPack } from "modules/alert/alert_pack/alertPackSlice";
import i18n from "modules/i18n/i18nConfig";
import { removeAccents } from "modules/common/utils";
import { checkRights } from "modules/auth/utils";
import { reparseNumber } from "modules/common/utils";
import { keyToReparseInPack } from "../utils";
import history_app from "history_app";

import Back from "modules/common/components/back";
import MessageDisplay from "modules/common/components/MessageDisplay";
import { ToggleAdapter, DropDownAdapter } from "modules/common/components/form";
import MailingAddModalForm from "modules/mailing/components/MailingAddModalForm";
import AlertSensor from "./Templates/alert_sensor/AlertSensor";
import AlertSensorOverride from "./Templates/alert_sensor/AlertSensorOverride";
import ElecDistribution from "./Templates/elec_distri/ElecDistribution";
import ElecDistributionOverride from "./Templates/elec_distri/ElecDistributionOverride";
import ElecDysfunction from "./Templates/elec_dysfunction/ElecDysfunction";
import ElecDysfunctionOverride from "./Templates/elec_dysfunction/ElecDysfunctionOverride";
import AlertPredict from "./Templates/alert_predict/AlertPredict";
import AlertPredictOverride from "./Templates/alert_predict/AlertPredictOverride";

const AlertPack = (props) => {
    const { equipments, machines, sites, mailings } = props;
    const dispatch = useDispatch();
    const { id_pack } = useParams();
    const { alertpack, alertpacktype, sensor, dataflow, auth, notification } = useSelector((state) => state);
    const lng_options = useSelector((state) => state.i18n.options);

    const currentSite = alertpack?.alertpack?.current?.site;
    const predictEquipmentsBySite = _.chain(machines)
        .reduce((res, machine) => {
            if (machine.site === currentSite && machine.is_predict === true) {
                const equipment = _.find(equipments, { id: machine.equipment });
                if (equipment) res.push(equipment);
            }
            return res;
        }, [])
        .value();

    const rights = checkRights(alertpack.alertpack.current, auth.rights);
    const can_change = notification.srv_status.db_status === "rw" && _.includes(rights, "can_change");

    const initial = useMemo(() => {
        return {
            ...alertpack.alertpack.default,
            ...alertpack.alertpack.current
        };
    }, [alertpack.alertpack]);

    const submitForm = async (formData) => {
        if (notification.srv_status.db_status === "rw") {
            const reparseConfig = _.mapValues(formData?.configuration, (item, key) => {
                if (_.includes(keyToReparseInPack, key)) {
                    if (key === "duration") {
                        return reparseNumber(item) * 60; // Convertir les heures en secondes pour l'API
                    }
                    return reparseNumber(item);
                }
                return item;
            });

            const newFormData = { ...formData, configuration: reparseConfig };

            if (_.get(formData, "id") === undefined) {
                const action = await dispatch(createAlertPack({ formData: newFormData }));
                if (createAlertPack.fulfilled.match(action)) {
                    history_app.push("/alerts_packs");
                }
            } else {
                const action = await dispatch(updateAlertPack({ formData: newFormData }));
                if (updateAlertPack.fulfilled.match(action)) {
                    history_app.push("/alerts_packs");
                }
            }
        }
    };

    const renderTemplates = (values, form) => {
        const { type } = values;
        switch (type) {
            case 1:
                return <AlertSensor />;
            case 2:
                return <ElecDistribution />;
            case 3:
                return <ElecDysfunction form={form} />;
            case 4:
                return <AlertPredict />;
            default:
                return null;
        }
    };

    const renderAdditionnal = (form) => {
        const { type } = initial;
        switch (type) {
            case 1:
                return (
                    <AlertSensorOverride
                        pack={initial}
                        rights={rights}
                        sensors={sensor.sensors}
                        overrides={alertpack.alertpack.override.overrides}
                        notification={notification}
                    />
                );
            case 2:
                return (
                    <ElecDistributionOverride
                        pack={initial}
                        rights={rights}
                        dataflows={dataflow.dataflows}
                        overrides={alertpack.alertpack.override.overrides}
                        notification={notification}
                    />
                );
            case 3:
                return (
                    <ElecDysfunctionOverride
                        pack={initial}
                        rights={rights}
                        dataflows={dataflow.dataflows}
                        overrides={alertpack.alertpack.override.overrides}
                        notification={notification}
                    />
                );
            case 4:
                return (
                    <AlertPredictOverride
                        pack={initial}
                        rights={rights}
                        notification={notification}
                        overrides={alertpack.alertpack.override.overrides}
                        equipments={predictEquipmentsBySite}
                    />
                );
            default:
                return null;
        }
    };

    const mailings_id = useMemo(() => {
        return _.map(mailings, (item) => item.id);
    }, [mailings]);

    return (
        <Segment attached>
            <Grid centered verticalAlign="middle">
                <Grid.Row stretched verticalAlign="middle" className="pwaModuleHeader">
                    <Grid.Column width={2}>
                        <Back />
                    </Grid.Column>
                    <Grid.Column width={12} textAlign="center">
                        <Header as="h1">
                            {id_pack === undefined && <Trans>add pack</Trans>}
                            {id_pack !== undefined && <Trans>update pack</Trans>}
                        </Header>
                    </Grid.Column>
                    <Grid.Column width={2}></Grid.Column>
                </Grid.Row>
                <Grid.Row>
                    <Grid.Column width={16}>
                        <Container>
                            <Form
                                onSubmit={submitForm}
                                initialValues={initial}
                                render={({ handleSubmit, form, submitting, pristine, invalid, values }) => {
                                    const not_visible_mailings = _.chain(values.mailinglist).difference(mailings_id).size().value() > 0;
                                    return (
                                        <>
                                            <Header as="h2" attached="top" block textAlign="center">
                                                <Trans>Alert definition</Trans>
                                            </Header>
                                            <Segment attached>
                                                <form onSubmit={handleSubmit} className="ui form">
                                                    <Field
                                                        name="active"
                                                        label={i18n._(t`is_active`)}
                                                        labelposition="right"
                                                        component={ToggleAdapter}
                                                        defaultValue={false}
                                                    />
                                                    <Field
                                                        name="site"
                                                        label={i18n._(t`site`)}
                                                        placeholder={i18n._(t`select site`)}
                                                        options={_.chain(sites)
                                                            .map(({ key, text, value }) => ({ key, text, value }))
                                                            .orderBy((item) => {
                                                                return removeAccents(item.text).toLowerCase();
                                                            }, "asc")
                                                            .value()}
                                                        component={DropDownAdapter}
                                                        disabled={!_.isUndefined(id_pack)}
                                                        isRequired={true}
                                                        validate={(value) => {
                                                            if (value === null) return <Trans>site is required</Trans>;
                                                            return undefined;
                                                        }}
                                                    />
                                                    <Field
                                                        name="type"
                                                        label={i18n._(t`packType`)}
                                                        placeholder={i18n._(t`select pack type`)}
                                                        options={alertpacktype.alertpacktypes}
                                                        component={DropDownAdapter}
                                                        disabled={!_.isUndefined(id_pack)}
                                                        isRequired={true}
                                                        validate={(value) => (!value ? <Trans>packType is required</Trans> : undefined)}
                                                    />
                                                    <Field
                                                        name="language"
                                                        label={i18n._(t`language`)}
                                                        placeholder={i18n._(t`select pack language`)}
                                                        options={lng_options}
                                                        component={DropDownAdapter}
                                                        isRequired={true}
                                                        validate={(value) => (!value ? <Trans>language is required</Trans> : undefined)}
                                                    />
                                                    <Grid centered verticalAlign="bottom" textAlign="center">
                                                        <Grid.Column
                                                            mobile={can_change ? 13 : 16}
                                                            tablet={can_change ? 15 : 16}
                                                            computer={can_change ? 15 : 16}
                                                        >
                                                            <Field
                                                                name="mailinglist"
                                                                label={i18n._(t`mailinglists`)}
                                                                placeholder={i18n._(t`choose mailinglists`)}
                                                                options={mailings}
                                                                multipleselect={1}
                                                                component={DropDownAdapter}
                                                                noResultsMessage={i18n._(t`no mailinglists found`)}
                                                                validate={(value) =>
                                                                    _.size(value) === 0 ? <Trans>mailinglist can't be empty</Trans> : undefined
                                                                }
                                                            />
                                                        </Grid.Column>
                                                        {can_change && (
                                                            <Grid.Column width={1} textAlign="center">
                                                                <MailingAddModalForm form={form} mailings={mailings} />
                                                            </Grid.Column>
                                                        )}
                                                    </Grid>
                                                    {not_visible_mailings && (
                                                        <div style={{ color: "#794b02", fontStyle: "italic" }}>
                                                            <Trans>Some mailing lists are present but you do not have permission to view them</Trans>
                                                        </div>
                                                    )}
                                                    {renderTemplates(values, form)}
                                                    {can_change && (
                                                        <Segment basic>
                                                            <Grid centered verticalAlign="top">
                                                                {_.isUndefined(id_pack) && (
                                                                    <Grid.Column width={16}>
                                                                        <MessageDisplay
                                                                            message={i18n._(t`exception_after_submit_alert`)}
                                                                            level="info"
                                                                            iconName="warning"
                                                                            isLoading={false}
                                                                            attached={false}
                                                                        />
                                                                    </Grid.Column>
                                                                )}
                                                                <Grid.Column width={16}>
                                                                    <Button
                                                                        type="submit"
                                                                        content={i18n._(t`validate`)}
                                                                        positive
                                                                        disabled={submitting || pristine || invalid}
                                                                    />
                                                                </Grid.Column>
                                                            </Grid>
                                                        </Segment>
                                                    )}
                                                </form>
                                            </Segment>
                                            {!_.isUndefined(id_pack) && renderAdditionnal(form)}
                                        </>
                                    );
                                }}
                            />
                        </Container>
                    </Grid.Column>
                </Grid.Row>
            </Grid>
        </Segment>
    );
};

export default React.memo(AlertPack);
