import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import { t, Trans } from "@lingui/macro";
import _ from "lodash";
import { Grid, Header, Segment } from "semantic-ui-react";
import { Hint } from "react-vis";
import ReactSpeedometer from "react-d3-speedometer";

import i18n from "modules/i18n/i18nConfig";
import { setTime } from "modules/overview/overviewSlice";
import { dateRangeOptions } from "modules/time/utils";
import { useGetPumpMonitoringQuery } from "modules/machine/machineService";

import BarGauge from "modules/common/components/graphic/BarGauge";
import TimePanel from "modules/common/components/TimePanel";
import MessageDisplay from "modules/common/components/MessageDisplay";
import GraphicDetail from "../graphic/GraphicDetail";
import RotatedLabelRadialChart from "modules/common/components/graphic/PieChart";
import HeaderWHelper from "modules/common/components/HeaderWHelper";
import tinycolor from "tinycolor2";

const rangeOptions = _.filter(dateRangeOptions(), (item) => !_.includes(["24h", "48h", "72h"], item.value));

const TabPumpEfficiency = ({ default_time, equipment, pump_description_empty }) => {
    const dispatch = useDispatch();
    const [value, setValue] = useState(null);
    const { org } = useSelector((state) => state);
    const { pumptime } = useSelector((state) => state.overview);

    const id_machine = equipment?.machine?.id ?? null;

    const rangeTime = pumptime.time
        ? {
              start: moment(pumptime.time.start),
              end: moment(pumptime.time.end),
              plage: pumptime.time.plage
          }
        : default_time;

    const start = pumptime?.time?.start ?? null;
    const end = pumptime?.time?.end ?? null;
    const pump_params = {
        org: org.current,
        id_machine,
        start: start ? start : default_time.start.toISOString(),
        end: end ? end : default_time.end.toISOString()
    };
    const pump_monitoring = useGetPumpMonitoringQuery(pump_params, { skip: !org.current });

    const {
        motor_efficiency = null,
        pump_efficiency = null,
        power_loss_repartition = [],
        bep_loss_total = null,
        bep_loss_percent = null,
        bep_spot_kw = null,
        bep_spot_percent = null
    } = pump_monitoring?.data || {};

    return (
        <Grid id="pwaMain" verticalAlign="top" centered>
            <Grid.Row>
                <Grid.Column width={16} textAlign="center">
                    <TimePanel
                        time={rangeTime}
                        rangeOptions={rangeOptions}
                        accordion={true}
                        action={({ start, end, plage }) => {
                            dispatch(setTime({ start, end, plage, tab: "pumptime" }));
                        }}
                        maximumDeltaDay={90}
                    />
                </Grid.Column>
            </Grid.Row>
            <Grid.Row>
                <Grid.Column mobile={16} computer={14} textAlign="center" className="pwaGraph">
                    <Header as="h3" block textAlign="center" attached="top">
                        <Trans>detail</Trans>
                    </Header>
                    {(pump_monitoring.isLoading || pump_monitoring.isFetching) && (
                        <MessageDisplay message={i18n._(t`loading data`)} level="info" iconName="circle notched" isLoading={true} />
                    )}
                    {pump_monitoring.isError && !pump_monitoring.isFetching && (
                        <>
                            {!pump_description_empty && (
                                <MessageDisplay message={i18n._(t`error loading data`)} level="error" iconName="warning circle" isLoading={false} />
                            )}
                            {pump_description_empty && (
                                <MessageDisplay message={i18n._(t`pump not configured`)} level="error" iconName="warning circle" isLoading={false} />
                            )}
                        </>
                    )}
                    {pump_monitoring.isSuccess && !pump_monitoring.isFetching && (
                        <GraphicDetail time={rangeTime} data={_.get(pump_monitoring, "data.detail", [])} csvName={equipment?.name ?? "export"} />
                    )}
                </Grid.Column>
            </Grid.Row>
            <Grid.Row>
                <Grid.Column mobile={16} tablet={16} computer={4} textAlign="center">
                    <Header block textAlign="center" attached="top">
                        <HeaderWHelper
                            text={<Trans>pump efficiency</Trans>}
                            helper={
                                <Trans>
                                    <p>
                                        Pump efficiency (The proportion of mechanical power input at the rotating shaft which is effectively converted
                                        to hydraulic power).
                                    </p>
                                </Trans>
                            }
                        />
                    </Header>
                    {(pump_monitoring.isLoading || pump_monitoring.isFetching) && (
                        <MessageDisplay message={i18n._(t`loading data`)} level="info" iconName="circle notched" isLoading={true} />
                    )}
                    {pump_monitoring.isError && !pump_monitoring.isFetching && (
                        <>
                            {!pump_description_empty && (
                                <MessageDisplay message={i18n._(t`error loading data`)} level="error" iconName="warning circle" isLoading={false} />
                            )}
                            {pump_description_empty && (
                                <MessageDisplay message={i18n._(t`pump not configured`)} level="error" iconName="warning circle" isLoading={false} />
                            )}
                        </>
                    )}
                    {pump_monitoring.isSuccess && !pump_monitoring.isFetching && (
                        <>
                            {!_.isFinite(pump_efficiency) && (
                                <MessageDisplay message={i18n._(t`no data`)} level="warning" iconName="warning circle" isLoading={false} />
                            )}
                            {_.isFinite(pump_efficiency) && (
                                <Segment attached style={{ display: "flex", justifyContent: "center", alignItems: "center", minHeight: "200px" }}>
                                    <ReactSpeedometer
                                        value={_.chain(pump_efficiency).clamp(0, 100).value()}
                                        currentValueText={`${i18n.number(_.chain(pump_efficiency).clamp(0, 100).value(), {
                                            maximumFractionDigits: 0
                                        })} %`}
                                        ringWidth={25}
                                        customSegmentStops={[0, 70, 85, 100]}
                                        segmentColors={["red", "orange", "limegreen"]}
                                        paddingHorizontal={0}
                                        paddingVertical={0}
                                        needleHeightRatio={0.8}
                                        needleColor={"gray"}
                                        minValue={0}
                                        maxValue={100}
                                        width={210}
                                        height={140}
                                    />
                                </Segment>
                            )}
                        </>
                    )}
                </Grid.Column>
                <Grid.Column mobile={16} tablet={16} computer={4} textAlign="center">
                    <Header block textAlign="center" attached="top">
                        <HeaderWHelper
                            text={<Trans>motor efficiency</Trans>}
                            helper={
                                <Trans>
                                    <p>
                                        Motor efficiency (The proportion of electrical power input which is effectively converted to mechanical power
                                        output at the rotating shaft).
                                    </p>
                                </Trans>
                            }
                        />
                    </Header>
                    {(pump_monitoring.isLoading || pump_monitoring.isFetching) && (
                        <MessageDisplay message={i18n._(t`loading data`)} level="info" iconName="circle notched" isLoading={true} />
                    )}
                    {pump_monitoring.isError && !pump_monitoring.isFetching && (
                        <>
                            {!pump_description_empty && (
                                <MessageDisplay message={i18n._(t`error loading data`)} level="error" iconName="warning circle" isLoading={false} />
                            )}
                            {pump_description_empty && (
                                <MessageDisplay message={i18n._(t`pump not configured`)} level="error" iconName="warning circle" isLoading={false} />
                            )}
                        </>
                    )}
                    {pump_monitoring.isSuccess && !pump_monitoring.isFetching && (
                        <>
                            {!_.isFinite(motor_efficiency) && (
                                <MessageDisplay message={i18n._(t`no data`)} level="warning" iconName="warning circle" isLoading={false} />
                            )}
                            {_.isFinite(motor_efficiency) && (
                                <Segment attached style={{ display: "flex", justifyContent: "center", alignItems: "center", minHeight: "200px" }}>
                                    <ReactSpeedometer
                                        value={_.chain(motor_efficiency).clamp(0, 100).value()}
                                        currentValueText={`${i18n.number(_.chain(motor_efficiency).clamp(0, 100).value(), {
                                            maximumFractionDigits: 0
                                        })} %`}
                                        ringWidth={25}
                                        customSegmentStops={[0, 70, 85, 100]}
                                        segmentColors={["red", "orange", "limegreen"]}
                                        paddingHorizontal={0}
                                        paddingVertical={0}
                                        needleHeightRatio={0.8}
                                        needleColor={"gray"}
                                        minValue={0}
                                        maxValue={100}
                                        width={210}
                                        height={140}
                                    />
                                </Segment>
                            )}
                        </>
                    )}
                </Grid.Column>
                <Grid.Column mobile={16} tablet={16} computer={6} textAlign="center">
                    <Header as="h3" block textAlign="center" attached="top">
                        <Trans>power loss repartition</Trans>
                    </Header>
                    {(pump_monitoring.isLoading || pump_monitoring.isFetching) && (
                        <MessageDisplay message={i18n._(t`loading data`)} level="info" iconName="circle notched" isLoading={true} />
                    )}
                    {pump_monitoring.isError && !pump_monitoring.isFetching && (
                        <>
                            {!pump_description_empty && (
                                <MessageDisplay message={i18n._(t`error loading data`)} level="error" iconName="warning circle" isLoading={false} />
                            )}
                            {pump_description_empty && (
                                <MessageDisplay message={i18n._(t`pump not configured`)} level="error" iconName="warning circle" isLoading={false} />
                            )}
                        </>
                    )}
                    {pump_monitoring.isSuccess && !pump_monitoring.isFetching && (
                        <>
                            {_.size(power_loss_repartition) === 0 && (
                                <MessageDisplay message={i18n._(t`no data`)} level="warning" iconName="warning circle" isLoading={false} />
                            )}
                            {_.size(power_loss_repartition) > 0 && (
                                <Segment attached style={{ minHeight: "200px" }}>
                                    <RotatedLabelRadialChart
                                        className="pwaGlobalOverviewRadialChart"
                                        getLabel={(d) => (d.percent > 1 ? i18n._(d.name) : "...")}
                                        colorType="literal"
                                        data={power_loss_repartition}
                                        onValueMouseOver={(v) => setValue(v)}
                                        onSeriesMouseOut={(v) => setValue(null)}
                                        radius={60}
                                        labelsRadiusMultiplier={1.2}
                                        labelsStyle={{ fontSize: 12 }}
                                        labelsRotation={-20}
                                        showLabels
                                        style={{ stroke: "#fff", strokeWidth: 2 }}
                                        width={210}
                                        height={190}
                                        margin={{ top: 0, bottom: 0 }}
                                    >
                                        {value !== null && (
                                            <Hint value={value}>
                                                <Segment style={{ color: tinycolor(value.color).setAlpha(1).toString() }}>{`${i18n._(value.name)}: ${
                                                    value.percent
                                                } %`}</Segment>
                                            </Hint>
                                        )}
                                    </RotatedLabelRadialChart>
                                </Segment>
                            )}
                        </>
                    )}
                </Grid.Column>
            </Grid.Row>
            <Grid.Row>
                <Grid.Column mobile={16} tablet={16} computer={7} textAlign="center">
                    <Header attached="top" block textAlign="center">
                        <HeaderWHelper
                            text={
                                <>
                                    <Trans>Overconsumption</Trans>
                                    {_.isFinite(bep_spot_kw) && ` : ${i18n.number(bep_spot_kw, { maximumFractionDigits: 2 })} kW`}
                                </>
                            }
                            helper={
                                <Trans>
                                    <p>Wasted power due to the difference between current operating point and BEP (last data).</p>
                                </Trans>
                            }
                        />
                    </Header>
                    {(pump_monitoring.isLoading || pump_monitoring.isFetching) && (
                        <MessageDisplay message={i18n._(t`loading data`)} level="info" iconName="circle notched" isLoading={true} />
                    )}
                    {pump_monitoring.isError && !pump_monitoring.isFetching && (
                        <>
                            {!pump_description_empty && (
                                <MessageDisplay message={i18n._(t`error loading data`)} level="error" iconName="warning circle" isLoading={false} />
                            )}
                            {pump_description_empty && (
                                <MessageDisplay message={i18n._(t`pump not configured`)} level="error" iconName="warning circle" isLoading={false} />
                            )}
                        </>
                    )}
                    {pump_monitoring.isSuccess && !pump_monitoring.isFetching && (
                        <>
                            {!_.isFinite(bep_spot_percent) && (
                                <MessageDisplay message={i18n._(t`no data`)} level="warning" iconName="warning circle" isLoading={false} />
                            )}
                            {_.isFinite(bep_spot_percent) && (
                                <Segment attached>
                                    <BarGauge
                                        value={{
                                            text: `${i18n.number(bep_spot_percent, { maximumFractionDigits: 1 })} %`,
                                            numeric: bep_spot_percent
                                        }}
                                        minValue={0}
                                        maxValue={100}
                                        thresholds={[
                                            { value: 0, color: "green" },
                                            { value: 15, color: "orange" },
                                            { value: 30, color: "red" }
                                        ]}
                                        height={40}
                                        width={200}
                                        itemSpacing={2}
                                    />
                                </Segment>
                            )}
                        </>
                    )}
                </Grid.Column>
                <Grid.Column mobile={16} tablet={16} computer={7} textAlign="center">
                    <Header attached="top" block textAlign="center">
                        <HeaderWHelper
                            text={
                                <>
                                    <Trans>Losses compared with the BEP over the period</Trans>
                                    {_.isFinite(bep_loss_total) && ` : ${i18n.number(bep_loss_total, { maximumFractionDigits: 2 })} kWh`}
                                </>
                            }
                            helper={
                                <Trans>
                                    <p>
                                        Amount of energy that could have been saved if the pump was operating at its best efficiency over the period.
                                    </p>
                                </Trans>
                            }
                        />
                    </Header>
                    {(pump_monitoring.isLoading || pump_monitoring.isFetching) && (
                        <MessageDisplay message={i18n._(t`loading data`)} level="info" iconName="circle notched" isLoading={true} />
                    )}
                    {pump_monitoring.isError && !pump_monitoring.isFetching && (
                        <>
                            {!pump_description_empty && (
                                <MessageDisplay message={i18n._(t`error loading data`)} level="error" iconName="warning circle" isLoading={false} />
                            )}
                            {pump_description_empty && (
                                <MessageDisplay message={i18n._(t`pump not configured`)} level="error" iconName="warning circle" isLoading={false} />
                            )}
                        </>
                    )}
                    {pump_monitoring.isSuccess && !pump_monitoring.isFetching && (
                        <>
                            {!_.isFinite(bep_loss_percent) && (
                                <MessageDisplay message={i18n._(t`no data`)} level="warning" iconName="warning circle" isLoading={false} />
                            )}
                            {_.isFinite(bep_loss_percent) && (
                                <Segment attached>
                                    <BarGauge
                                        value={{
                                            text: `${i18n.number(bep_loss_percent, { maximumFractionDigits: 1 })} %`,
                                            numeric: bep_loss_percent
                                        }}
                                        minValue={0}
                                        maxValue={100}
                                        thresholds={[
                                            { value: 0, color: "green" },
                                            { value: 15, color: "orange" },
                                            { value: 30, color: "red" }
                                        ]}
                                        height={40}
                                        width={200}
                                        itemSpacing={2}
                                    />
                                </Segment>
                            )}
                        </>
                    )}
                </Grid.Column>
            </Grid.Row>
        </Grid>
    );
};

export default TabPumpEfficiency;
